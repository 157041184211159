import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';

import Skeleton from 'react-loading-skeleton';

import useStyles from '../styles/styles';

const Products = () => {
    const [productsState, setProductsState] = React.useState([]);
    const [productsCount, setProductsCount] = React.useState(0);
    const [productsPage, setProductsPage] = React.useState(1);

    React.useEffect(() => {
        fetch(`${process.env.API_URL}/products/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({ type: 'all', page: 1 })
        }).then(res => res.json()).then(res => {
            if (res.detail.data !== undefined) {
                setProductsState(res.detail.data);
                setProductsCount(Math.ceil(res.detail.count / 8));
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const classes = useStyles();

    const changePage = (e, p) => {
        setProductsState([]);
        fetch(`${process.env.API_URL}/products/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({ type: 'all', page: p })
        }).then(res => res.json()).then(res => {
            if (res.detail.data !== undefined) {
                setProductsState(res.detail.data);
                setProductsCount(Math.ceil(res.detail.count / 8));
                setProductsPage(p);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div className={classes.whiteBackgroundContainer}>
            <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                <div className={classes.productsGridContainer}>
                    {
                        productsState.length > 0 ?
                        productsState.map((el, ind) => {
                            return (
                                <NavLink key={ind} to={`/product/${el.id}`} exact activeClassName={classes.linkNoDecoration} className={classes.linkNoDecoration}>
                                    <div style={{ cursor: 'pointer', height: '330px', border: '1px solid #e6e6e6', borderRadius: '0.75em', padding: '1.5em' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={el.img} width="81%" />
                                        </div>
                                        <div style={{ padding: '1em 0' }}>
                                            <div>
                                                <span style={{ fontSize: '1.23em' }}>{el.name}</span>
                                            </div>
                                            <div style={{ paddingTop: '0.45em' }}>
                                                {
                                                    el.discount_price !== 0 &&
                                                    <span style={{ color: 'gray', fontSize: '1.17em', textDecoration: 'line-through', textDecorationThickness: '3px', marginRight: '0.3em' }}>${el.discount_price}</span>
                                                }
                                                <span style={{ color: '#19a1ff', fontSize: '1.17em' }}>${el.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        })
                        :
                        [...Array(8)].map((el, ind) => {
                            return <div key={ind}><Skeleton height={360} /></div>
                        })
                    }
                </div>
                <div style={{ padding: '1.5em 0 4.5em 0' }}>
                    <Pagination count={productsCount} onChange={changePage} />
                </div>
            </Container>
        </div>
    )
};

export default Products;

import React from 'react';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';

import DesktopMenu from './DesktopMenu';

import { observer, inject } from 'mobx-react';

import useStyles from '../styles/styles';

export default inject('uiStateStore')(observer((props) => {
    const classes = useStyles();

    const toggleDrawer = (open) => () => {
        props.uiStateStore.setDrawerState(open);
    };

    const SideList = () => (
        <div className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)} >
            <div style={{ textAlign: 'center', display: 'grid', color: 'white' }}>
                <h1 className={classes.title}>
                    State Filing Solution
                </h1>
                <MobileMenu />
            </div>
        </div>
    );

    const MobileMenu = () => {
        if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            return (
                <React.Fragment>
                    <DesktopMenu fontColor="black" />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Button>
                        <NavLink to="/products" exact activeClassName={classes.activeNavLink} className={classes.navLink}>All Products</NavLink>
                    </Button>
                    <Button>
                        <NavLink to="/contact-us" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Contact Us</NavLink>
                    </Button>
                    <Button>
                        <NavLink to="/cart" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Cart</NavLink>
                    </Button>
                    <Button>
                        <NavLink to="/login" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Sign in</NavLink>
                    </Button>
                </React.Fragment>
            );
        }
    }

    const MiddleMenu = () => {
        return (
            <div style={{ display: 'flex', gap: '0.6em', alignItems: 'center' }}>
                <Button>
                    <NavLink to="/products" exact activeClassName={classes.activeNavLink} className={classes.navLink}>All Products</NavLink>
                </Button>
                <Button>
                    <NavLink to="/contact-us" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Contact Us</NavLink>
                </Button>
            </div>
        )
    }

    const RightMenu = inject('uiStateStore')(observer(() => {
        if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            return (
                <div style={{ display: 'flex', gap: '0.9em', alignItems: 'center' }}>
                    <NavLink to="/logout" exact activeClassName={classes.activeNavLink} className={classes.navLink}>
                        <ExitToAppIcon style={{ color: '#000', verticalAlign: 'middle' }} />
                    </NavLink>
                    <NavLink to="/account" exact activeClassName={classes.activeNavLink} className={classes.navLink}>
                        <PersonIcon style={{ color: '#000', verticalAlign: 'middle' }} />
                    </NavLink>
                    <NavLink to="/cart" exact activeClassName={classes.activeNavLink} className={classes.navLink}>
                        <Badge badgeContent={props.uiStateStore.uiState.cart.length} color="primary">
                            <ShoppingCartIcon style={{ color: '#000', verticalAlign: 'middle' }} />
                        </Badge>
                    </NavLink>
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', gap: '0.9em', alignItems: 'center' }}>
                    <NavLink to="/login" exact activeClassName={classes.activeNavLink} className={classes.navLink}>
                        <PersonIcon style={{ color: '#000', verticalAlign: 'middle' }} />
                    </NavLink>
                    <NavLink to="/cart" exact activeClassName={classes.activeNavLink} className={classes.navLink}>
                        <Badge badgeContent={props.uiStateStore.uiState.cart.length} color="primary">
                            <ShoppingCartIcon style={{ color: '#000', verticalAlign: 'middle' }} />
                        </Badge>
                    </NavLink>
                </div>
            );
        }
    }))

    return (
        <header>
            <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
                <div className={classes.navMenuContainer}>
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <NavLink to="/" exact activeClassName={classes.title} className={classes.title}>
                            <h1 className={classes.title}>
                                State Filing Solution
                            </h1>
                        </NavLink>
                        <div className={classes.desktopNav}>
                            <MiddleMenu />
                        </div>
                        <div className={classes.desktopNav}>
                            <RightMenu />
                        </div>
                        <div className={classes.mobileNav}>
                            <MenuIcon onClick={toggleDrawer(true)} style={{ width: '30px', height: '30px' }} />
                        </div>
                        <Drawer open={props.uiStateStore.uiState.drawerState} onClose={toggleDrawer(false)}>
                            <SideList />
                        </Drawer>
                    </Toolbar>
                </div>
            </AppBar>
        </header>
    );
}));

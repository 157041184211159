import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';

import Skeleton from 'react-loading-skeleton';

import useStyles from '../styles/styles';

import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';

const authData = {
    apiLoginID: process.env.API_LOGIN_ID,
    clientKey: process.env.API_CLIENT_KEY,
};

const AuthorizeNetHostedForm = ({
    clientDataState,
    loadingState,
    setLoadingState,
    setAlertState,
    setCheckoutState,
    checkoutState,
    cart,
}) => {
    const handleSubmit = (hostedFormResponse) => {
        setLoadingState(true);
        if (hostedFormResponse.messages.resultCode === 'Ok') {
            fetch(`${process.env.API_URL}/authorize-net-wh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...hostedFormResponse.opaqueData,
                    ...clientDataState,
                    ...checkoutState,
                    cart,
                    type: '0'
                })
            }).then(res => res.json()).then(res => {
                if (res.detail === 'Success') {
                    setAlertState('show', false);
                    setAlertState('message', '');
                    setLoadingState(false);
                    setCheckoutState({
                        ...checkoutState,
                        step: 4,
                    });
                } else {
                    setLoadingState(false);
                    setAlertState('show', true);
                    setAlertState('state', 'warning');
                    setAlertState('message', 'Payment failed please refresh page and try again.');
                }
            }).catch(err => {
                setLoadingState(false);
                setLoadingState(false);
                setAlertState('show', true);
                setAlertState('state', 'warning');
                setAlertState('message', 'Payment failed please refresh page and try again.');
            });
        } else {
            setLoadingState(false);
            setLoadingState(false);
            setAlertState('show', true);
            setAlertState('state', 'warning');
            setAlertState('message', 'Payment failed please refresh page and try again.');
        }
    };

    return (
        <div className="row">
            <div className="col-1" />
            <div className="col-5 d-flex align-items-center justify-content-center">
                <HostedForm
                    environment="PRODUCTION"
                    authData={authData}
                    onSubmit={handleSubmit}
                    buttonStyle={{
                        color: loadingState ? '#000' : '#fff',
                        width: '100%',
                        padding: '12px',
                        border: '0',
                        borderRadius: '4.5px',
                        backgroundColor: loadingState ? 'rgb(242 243 245)' : '#1aa1ff',
                        cursor: 'pointer',
                    }}
                    buttonText={loadingState ? 'Verifying Payment..' : `Pay`}
                    formButtonText={loadingState ? 'Verifying Payment..' : `Pay`}
                />
            </div>
        </div>
    );
};

const Checkout = inject('uiStateStore', 'alertStore')(observer((props) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (props.uiStateStore.uiState.cart.length === 0) navigate('/products');
    }, []);

    const [checkoutState, setCheckoutState] = React.useState({
        step: 1,
        emailHelperText: '',
        firstNameHelperText: '',
        lastNameHelperText: '',
        companyHelperText: '',
        addressHelperText: '',
        cityHelperText: '',
        countryHelperText: '',
        stateHelperText: '',
        zipHelperText: '',
        phoneHelperText: ''
    });

    const [loadingState, setLoadingState] = React.useState(false);

    const classes = useStyles();

    const continueToShipping = () => {
        if (props.uiStateStore.checkoutState.email === '') {
            setCheckoutState({
                ...checkoutState,
                emailHelperText: 'Email cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.first_name === '') {
            setCheckoutState({
                ...checkoutState,
                firstNameHelperText: 'First name cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.last_name === '') {
            setCheckoutState({
                ...checkoutState,
                lastNameHelperText: 'Last name cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.phone === '') {
            setCheckoutState({
                ...checkoutState,
                phoneHelperText: 'Phone cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.company === '') {
            setCheckoutState({
                ...checkoutState,
                companyHelperText: 'Company cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.address === '') {
            setCheckoutState({
                ...checkoutState,
                addressHelperText: 'Address cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.city === '') {
            setCheckoutState({
                ...checkoutState,
                cityHelperText: 'City cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.country === '') {
            setCheckoutState({
                ...checkoutState,
                countryHelperText: 'Country cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.state === '') {
            setCheckoutState({
                ...checkoutState,
                stateHelperText: 'State cannot be empty'
            });
            return;
        } else if (props.uiStateStore.checkoutState.zip === '') {
            setCheckoutState({
                ...checkoutState,
                zipHelperText: 'Zip code cannot be empty'
            });
            return;
        } else {
            setCheckoutState({
                ...checkoutState,
                emailHelperText: '',
                firstNameHelperText: '',
                lastNameHelperText: '',
                companyHelperText: '',
                addressHelperText: '',
                cityHelperText: '',
                countryHelperText: '',
                stateHelperText: '',
                zipHelperText: '',
                step: 2,
            });
        }
    };

    const continueToPayment = () => {
        setCheckoutState({
            ...checkoutState,
            step: 3,
        });
    };

    const switchStep = (step) => {
        setCheckoutState({
            ...checkoutState,
            step: step,
        });
    };

    const payWithGumroad = (e) => {
        e.preventDefault();

        if (props.uiStateStore.uiState.cart[0].gumroad === '') {
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'info');
            props.alertStore.setAlertState('message', 'Online payment is currently disabled, please use cash or check. Thank you for your patience.');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 15000);
            return;
        }

        else {
            setLoadingState(true);

            const bodyData = {
                ...toJS(props.uiStateStore.checkoutState),
                company: '',
                id: '', // acc
                _id: '',
                product: props.uiStateStore.uiState.cart[0].name
            };

            fetch(`${process.env.API_URL}/custom-payment/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData)
            }).then(res => res.json()).then(res => {
                if (res.detail === 'Success') {
                    //window.open(document.getElementById('gumroad-link').getAttribute("href"), '_blank');
                    //document.getElementById('gumroad-link').click();
                    setTimeout(() => {
                        window.location.href = document.getElementById('gumroad-link').getAttribute("href");
                    }, 150);
                } else {
                    props.alertStore.setAlertState('show', true);
                    props.alertStore.setAlertState('state', 'info');
                    props.alertStore.setAlertState('message', res.detail);

                    setTimeout(() => {
                        props.alertStore.setAlertState('show', false);
                        props.alertStore.setAlertState('message', '');
                    }, 15000);
                    setLoadingState(false);
                }
            }).catch((err) => {
                props.alertStore.setAlertState('show', true);
                props.alertStore.setAlertState('state', 'info');
                props.alertStore.setAlertState('message', `Unable to process payment ${err}`);

                setTimeout(() => {
                    props.alertStore.setAlertState('show', false);
                    props.alertStore.setAlertState('message', '');
                }, 15000);
                setLoadingState(false);
            });
        }
    }

    return (
        <div className={classes.whiteBackgroundContainer}>
            <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                <div className={classes.checkoutGridContainer}>
                    {
                        checkoutState.step === 1 ?
                            <div>
                                <div style={{ display: 'flex', gap: '0.45em' }}>
                                    <NavLink to="/cart" exact activeClassName={classes.linkNoDecoration} className={classes.linkNoDecoration}>
                                        <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: '1.11em' }}>Cart</span>
                                            <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                        </div>
                                    </NavLink>
                                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => switchStep(1)}>
                                        <span style={{ fontSize: '1.11em', color: '#19a1ff' }}>Information</span>
                                        <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#19a1ff', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: '1.11em', color: '#b3b0b0' }}>Shipping</span>
                                        <ChevronRightIcon style={{ verticalAlign: 'middle', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: '1.11em', color: '#b3b0b0' }}>Payment</span>
                                    </div>
                                </div>

                                <div style={{ paddingTop: '2.7em' }}>
                                    <div>
                                        <span style={{ fontSize: '1.5em' }}>Contact information</span>
                                    </div>
                                    <div style={{ padding: '1.11em 0' }}>
                                        <TextField value={props.uiStateStore.checkoutState.email} onChange={props.uiStateStore.changeEmail} label="Email" variant="outlined" fullWidth helperText={checkoutState.emailHelperText} error={checkoutState.emailHelperText !== ''} />
                                    </div>
                                </div>

                                <div style={{ paddingTop: '0.75em' }}>
                                    <div>
                                        <span style={{ fontSize: '1.5em' }}>Shipping address</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.6em', padding: '1.11em 0' }}>
                                        <div style={{ display: 'flex', gap: '0.6em' }}>
                                            <TextField value={props.uiStateStore.checkoutState.first_name} onChange={props.uiStateStore.changeFirstName} label="First Name" variant="outlined" fullWidth helperText={checkoutState.firstNameHelperText} error={checkoutState.firstNameHelperText !== ''} />
                                            <TextField value={props.uiStateStore.checkoutState.last_name} onChange={props.uiStateStore.changeLastName} label="Last Name" variant="outlined" fullWidth helperText={checkoutState.lastNameHelperText} error={checkoutState.lastNameHelperText !== ''} />
                                        </div>
                                        <TextField value={props.uiStateStore.checkoutState.phone} onChange={props.uiStateStore.changePhone} label="Phone" variant="outlined" fullWidth helperText={checkoutState.phoneHelperText} error={checkoutState.phoneHelperText !== ''} />
                                        <TextField value={props.uiStateStore.checkoutState.company} onChange={props.uiStateStore.changeCompany} label="Company" variant="outlined" fullWidth helperText={checkoutState.companyHelperText} error={checkoutState.companyHelperText !== ''} />
                                        <div style={{ display: 'flex', gap: '0.6em' }}>
                                            <TextField value={props.uiStateStore.checkoutState.address} onChange={props.uiStateStore.changeAddress} label="Address" variant="outlined" fullWidth helperText={checkoutState.addressHelperText} error={checkoutState.addressHelperText !== ''} />
                                            <TextField value={props.uiStateStore.checkoutState.city} onChange={props.uiStateStore.changeCity} label="City" variant="outlined" fullWidth helperText={checkoutState.cityHelperText} error={checkoutState.cityHelperText !== ''} />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.6em' }}>
                                            <TextField value={props.uiStateStore.checkoutState.country} onChange={props.uiStateStore.changeCountry} label="Country" variant="outlined" fullWidth helperText={checkoutState.countryHelperText} error={checkoutState.countryHelperText !== ''} />
                                            <TextField value={props.uiStateStore.checkoutState.state} onChange={props.uiStateStore.changeState} label="State/region" variant="outlined" fullWidth helperText={checkoutState.stateHelperText} error={checkoutState.stateHelperText !== ''} />
                                            <TextField value={props.uiStateStore.checkoutState.zip} onChange={props.uiStateStore.changeZip} label="ZIP code" variant="outlined" fullWidth helperText={checkoutState.zipHelperText} error={checkoutState.zipHelperText !== ''} />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ paddingTop: '0.75em' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        classes={{ root: classes.headerStartNowButton }}
                                        onClick={continueToShipping}>
                                        CONTINUE TO SHIPPING
                                    </Button>
                                </div>
                            </div>
                            :
                            checkoutState.step === 2 ?
                                <div>
                                    <div style={{ display: 'flex', gap: '0.45em' }}>
                                        <NavLink to="/cart" exact activeClassName={classes.linkNoDecoration} className={classes.linkNoDecoration}>
                                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ fontSize: '1.11em' }}>Cart</span>
                                                <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                            </div>
                                        </NavLink>
                                        <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => switchStep(1)}>
                                            <span style={{ fontSize: '1.11em', color: '#000' }}>Information</span>
                                            <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => switchStep(2)}>
                                            <span style={{ fontSize: '1.11em', color: '#19a1ff' }}>Shipping</span>
                                            <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#19a1ff', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: '1.11em', color: '#b3b0b0' }}>Payment</span>
                                        </div>
                                    </div>

                                    <div className={classes.roundDiv} style={{ marginTop: '2.7em', paddingTop: '0', paddingBottom: '0' }}>
                                        <div style={{ display: 'flex', gap: '0.3em', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgb(197, 197, 197)', padding: '0.6em 0' }}>
                                            <span style={{ color: '#8b8a8a' }}>Contact</span>
                                            <span>{props.uiStateStore.checkoutState.email}</span>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => switchStep(1)}>
                                                Change
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.3em', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', padding: '0.6em 0' }}>
                                            <span style={{ color: '#8b8a8a' }}>Shipping</span>
                                            <span>{props.uiStateStore.checkoutState.address}</span>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => switchStep(1)}>
                                                Change
                                            </Button>
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '1.5em' }}>
                                        <div>
                                            <span style={{ fontSize: '1.5em' }}>Shipping method</span>
                                        </div>
                                        <div className={classes.roundDiv} style={{ borderColor: '#19a1ff', borderWidth: '2.1px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.09em' }}>
                                                    <span>
                                                        Standard
                                                    </span>
                                                    <span style={{ color: 'gray' }}>
                                                        4 to 7 business days
                                                    </span>
                                                </div>
                                                <div>
                                                    $0.00
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: '0.75em' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            classes={{ root: classes.headerStartNowButton }}
                                            onClick={continueToPayment}>
                                            CONTINUE TO PAYMENT
                                        </Button>
                                    </div>
                                </div>
                                :
                                checkoutState.step === 3 ?
                                    <div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <NavLink to="/cart" exact activeClassName={classes.linkNoDecoration} className={classes.linkNoDecoration}>
                                                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: '1.11em' }}>Cart</span>
                                                    <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                                </div>
                                            </NavLink>
                                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => switchStep(1)}>
                                                <span style={{ fontSize: '1.11em', color: '#000' }}>Information</span>
                                                <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                            </div>
                                            <div style={{ cursor: 'pointer', isplay: 'flex', alignItems: 'center' }} onClick={() => switchStep(2)}>
                                                <span style={{ fontSize: '1.11em', color: '#000' }}>Shipping</span>
                                                <ChevronRightIcon style={{ verticalAlign: 'middle', color: '#000', fontSize: '1.11em', marginBottom: '0.09em' }} />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => switchStep(3)}>
                                                <span style={{ fontSize: '1.11em', color: '#19a1ff' }}>Payment</span>
                                            </div>
                                        </div>

                                        <div className={classes.roundDiv} style={{ marginTop: '2.7em', paddingTop: '0', paddingBottom: '0' }}>
                                            <div style={{ display: 'flex', gap: '0.3em', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgb(197, 197, 197)', padding: '0.6em 0' }}>
                                                <span style={{ color: '#8b8a8a' }}>Contact</span>
                                                <span>{props.uiStateStore.checkoutState.email}</span>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => switchStep(1)}>
                                                    Change
                                                </Button>
                                            </div>
                                            <div style={{ display: 'flex', gap: '0.3em', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', padding: '0.6em 0' }}>
                                                <span style={{ color: '#8b8a8a' }}>Shipping</span>
                                                <span>{props.uiStateStore.checkoutState.address}</span>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => switchStep(1)}>
                                                    Change
                                                </Button>
                                            </div>
                                        </div>

                                        <div style={{ paddingTop: '1.5em' }}>
                                            <div>
                                                <span style={{ fontSize: '1.5em' }}>Payment</span>
                                            </div>
                                            <div className={classes.roundDiv}>
                                            <span style={{ color: 'green' }}>Information verified. Payment available.</span>
                                            </div>
                                        </div>

                                        <div style={{ paddingTop: '0.75em' }}>
                                            <AuthorizeNetHostedForm
                                                clientDataState={props.uiStateStore.checkoutState}
                                                loadingState={loadingState}
                                                setLoadingState={setLoadingState}
                                                setAlertState={props.alertStore.setAlertState}
                                                setCheckoutState={setCheckoutState}
                                                checkoutState={checkoutState}
                                                cart={props.uiStateStore.uiState.cart}
                                            />
                                        </div>
                                    </div>
                                    :
                                    checkoutState.step === 4 ?
                                    <div>
                                        <span>Thank you for your purchase please check your email for order invoice.</span>
                                    </div>
                                    :
                                    <div>
                                        <span>Please refresh page and reinitiate checkout again.</span>
                                    </div>
                    }
                    <div>
                        <div style={{ borderBottom: '1px solid rgb(197, 197, 197)' }}>
                            <span style={{ fontSize: '1.11em', color: '#b3b0b0' }}>{checkoutState.step === 4 ? 'You have purchased' : 'You are purchasing'} </span>
                            {
                                props.uiStateStore.uiState.cart.map((el, ind) => {
                                    return (
                                        <div key={ind} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className={classes.roundDiv} style={{ padding: '0.45em' }}>
                                                <img src={el.img} width="60px" />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#222222' }}>
                                                    {el.name}
                                                </span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#222222' }}>${el.price}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1em 0' }}>
                            <span style={{ fontSize: '1.5em' }}>Total: ${props.uiStateStore.totalCheckout()}</span>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}));

export default Checkout;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    base: {
        fontFamily: 'Open Sans, sans-serif',
        minHeight: '100vh',
        position: 'relative',
        fontWeight: '300'
    },
    root: {
        marginRight: theme.spacing(1)
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    wrapper: {
        backgroundColor: '#f4f6f8',
        margin: '0'
    },
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        width: '100%',
    },
    buttonProgress: {
        marginLeft: '9px',
    },
    button: {
        margin: theme.spacing(1),
    },
    tabPanel: {
        margin: theme.spacing(1),
        minWidth: 200,
        width: `100%`
    },
    activeNavLink: {
        textDecoration: 'none',
        color: '#bdbdbd',
    },
    navLink: {
        textDecoration: 'none',
        color: 'black',
        fontWeight: '300',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    homeActiveNavLink: {
        textDecoration: 'none',
        color: 'black',
    },
    homeNavLink: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '4.5px',
            width: '36%'
        },
        [theme.breakpoints.up('md')]: {
            padding: '15px',
            width: '21%'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '15px',
            width: '15%'
        },

    },
    footerLogo: {
        width: '60%',
        display: 'block',
        paddingBottom: '30px'
    },
    title: {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 300,
        color: '#333',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '27px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
        },
    },
    noColorTitle: {
        fontFamily: 'Jost, sans-serif',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '30px',
        },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    paymentEContainer: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 'auto'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '600px',
            margin: '0 auto'
        },
    },
    desktopNav: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'inherit',
        },
    },
    mobileNav: {
        [theme.breakpoints.down('sm')]: {
            display: 'inherit',
        },
        [theme.breakpoints.up('md')]: {
            display: 'inherit',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    whiteColor: {
        color: 'white'
    },
    footer: {
        left: '0',
        bottom: '0',
        right: '0',
        position: 'fixed',
        zIndex: 1,
    },
    loginButton: {
        width: '100%',
        padding: '15px 9px 15px 9px',
        backgroundColor: '#19a1ff',
        color: 'white',
        border: 0,
        borderRadius: '6px',
        marginTop: '15px'
    },
    footerDisclaimerText: {
        textAlign: 'center',
        paddingBottom: '75px',
        zIndex: 2
    },
    footer: {
        zIndex: 1,
    },
    footerSpacing: {
        padding: '30px'
    },
    footerSpacingIconDiv: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '60px',
            paddingLeft: '0'
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px',
            paddingLeft: '0'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '60px',
            paddingLeft: '0'
        },
    },
    footerHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            justifyContent: 'space-between',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '15px',
        },
        [theme.breakpoints.up(1200)]: {
            paddingLeft: '60px',
            paddingRight: '60px',
        },
        [theme.breakpoints.up(1410)]: {
            paddingLeft: '120px',
            paddingRight: '120px',
        },
    },
    footerIcon: {
        color: '#acabb0', marginLeft: '30px', marginRight: '30px'
    },
    footerMenuItem: {
        marginTop: '15px',
        marginBottom: '15px'
    },
    navMenuContainer: {
        boxShadow: '0px 0px 0 1px #e6e6e6',
    },
    gridView: {
        display: 'grid'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    scanTicketPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #f0f0f0',
        boxShadow: theme.shadows[5],
        fontFamily: 'Roboto, sans-serif',
        borderRadius: '6px',
        overflowY: 'auto',
        outline: 'none',

        [theme.breakpoints.down('sm')]: {
            width: '96%',
        },
        [theme.breakpoints.up('md')]: {
            width: '600px',
            height: '300px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '600px',
            height: '300px',
        },
    },
    signInModal: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #f0f0f0',
        fontFamily: 'Roboto, sans-serif',
        borderRadius: '7.5px',
        overflowY: 'auto',
        outline: 'none',

        [theme.breakpoints.down('sm')]: {
            width: '96%',
        },
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            height: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'auto',
            height: 'auto',
        },
    },
    logOff: {
        position: 'absolute',
        zIndex: 9999,
        margin: 'auto',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#000',
        color: 'white',
        textAlign: 'center',
        paddingTop: '90px'
    },
    basicButton: {
        boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '9px',
        border: '0',
        backgroundColor: 'transparent',
        display: 'flex',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            padding: '12px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '15px',
        },
    },
    processDiv: {
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px 60px 30px',
            borderRadius: '6px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px 30px 60px 30px',
            borderRadius: '6px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '60px',
            borderRadius: '6px'
        },
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.12)',
    },
    fingerPrintIcon: {
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex', margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '35px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '39px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '45px'
        },
    },
    homeGrid: {
        [theme.breakpoints.down('sm')]: {
            display: 'inherit'
        },
        [theme.breakpoints.up('md')]: {
            display: 'inherit'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid', gridTemplateColumns: '240px auto',
        },
    },
    homeMenu: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRight: '1px solid #e6e6e6',
            paddingBottom: '100%'
        },
    },
    accountIcon: {
        display: 'flex',
        margin: '0 auto',
        paddingTop: '30px',
        fontSize: '36px',
    },
    roundDiv: {
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '30px 15px 30px 15px',
        border: '1px solid #e6e6e6',
        marginBottom: '15px',
    },
    roundDivGreen: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: 'rgb(27, 39, 90)',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid rgb(27, 39, 90)',
        color: 'white',
    },
    roundDivPadding: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid #e6e6e6',
        color: 'black',
    },
    roundDivPurple: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '9px',
            marginBottom: '9px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        backgroundColor: '#1a0095',
        borderRadius: '6px',
        padding: '0px 15px 0px 15px',
        border: '1px solid #1a0095',
        color: 'white',
    },
    roundDivHover: {
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '30px 15px 30px 15px',
        border: '1px solid #e6e6e6',
        marginBottom: '15px',
        cursor: 'pointer',
        display: 'flex',
        gap: '0.9em',

        '&:hover': {
            border: '1px solid #19a1ff'
        }
    },
    homeCards: {
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '33% 33% auto',
            gridGap: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: '33% 33% auto',
            gridGap: '15px',
        },
    },
    homeLayoutGrid: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '9px', paddingRight: '9px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '15px', paddingRight: '15px'
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '15px', paddingRight: '15px'
        },
    },
    jobDiv: {
        marginTop: '15px',
        borderRadius: '6px', 
        height: '100%',
        padding: '3px 15px 0px 30px',
        backgroundColor: '#f4f6f8'
    },
    saveButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '3px', marginBottom: '15px', backgroundColor: '#e3e3e3', marginRight: '9px'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '15px', marginBottom: '15px', backgroundColor: '#e3e3e3'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '15px', marginBottom: '15px', backgroundColor: '#e3e3e3'
        },
    },
    loadMoreText: {
        [theme.breakpoints.down('sm')]: {
            fontWeight: '300', 
            padding: '0px 15px 15px 15px'
        },
        [theme.breakpoints.up('md')]: {
            fontWeight: '300', 
            padding: '30px 15px 15px 15px'
        },
        [theme.breakpoints.up('lg')]: {
            fontWeight: '300', 
            padding: '30px 15px 15px 15px'
        },   
    },
    loginContainer: {
        position: 'relative', margin: 'auto', padding: '90px 0',
        [theme.breakpoints.up('md')]: {
            width: '450px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '450px',
        }, 
    },
    dashboardMainButton: {
        color: 'white',
        borderRadius: '2px',
        border: 'none',
        fontSize: '12px',
        padding: '7.5px 22.2px 7.5px 22.2px',
        marginTop: '9px',
        cursor: 'pointer',
        marginRight: '6px'
    },
    cardElementDiv: {
        border: '1px solid #bcbdbf', borderRadius: '4.5px', padding: '10.5px',

        '&:focus': {
            boxShadow: 'inset 0 0 0 2px #19a1ff',
            border: '1px solid transparent',
            outline: 'none'
        },

        '&:hover': {
            border: '1px solid black'
        },
    },
    paymentDiv: {
        backgroundColor: '#fff',
        border: '1px solid #ddd', borderRadius: '4.5px',

        [theme.breakpoints.down('md')]: {
            padding: '9px', 
        },
        [theme.breakpoints.up('lg')]: {
            padding: '45px', 
        }, 
    },
    paymentPadding: {
        [theme.breakpoints.up('lg')]: {
            padding: '30px 0'
        },  
    },
    strikeText: {
        color: '#9e9e9e',
        background: 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)',
        marginRight: '9px'
    },
    invoiceBox: {
        maxWidth: "800px",
        margin: "auto",
        padding: "21px",
        border: "1px solid #eee",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
        color: "#555",
        backgroundColor: '#fff'
    },
    invoiceBoxTable: {
        width: "100%",
        lineHeight: "inherit",
        textAlign: "left"
    },
    invoiceBoxTableTrHeading: {
        background: "#eee",
        borderBottom: "1px solid #ddd",
        fontWeight: "bold"
    },
    invoiceBoxTableTd: {
        textAlign: 'right',
        padding: '4.5px'
    },
    invoiceBorder: {
        borderBottom: '1px solid gray'
    },
    invoicePadding: {
        padding: '4.5px'
    },
    clientSettingsContainer: {
        padding: '9px',
        backgroundColor: '#fff',
        marginBottom: '9px',
        borderRadius: '4.5px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.9em'
    },
    main: {
        display: 'flex',
    },
    mainContentFixed: {
        position: 'fixed', width: '100%', height: '100%', top: 0, right: 0, left: 0, bottom: 0
    },
    mainContentNoPadding: {
        flexGrow: 1,
    },
    mainContent: {
        flexGrow: 1,
    },
    mainContentFlex: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh'
    },
    flexAlignCenter: {
        display: 'flex', alignItems: 'center', flexDirection: 'column'
    },
    mobileDrawerMenuPaper: {
        padding: '15px 0',
        width: 300,
        border: '0',
        backgroundColor: theme.palette.type === 'dark' ? '#18191a' : '#f0f2f5'
    },
    drawerLogoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    drawerMenu: {
        [theme.breakpoints.down(1100)]: {
            display: 'none'
        },

        width: 300,
        flexShrink: 0,
    },
    drawerMenuPaper: {
        padding: '60px 0',
        width: 300,
        border: '0',
        backgroundColor: theme.palette.type === 'dark' ? '#18191a' : '#f0f2f5'
    },
    drawerMenuContainer: {
        overflow: 'auto'
    },
    drawerMenuButton: {
        borderRadius: '7.5px !important',
        
        '&:hover': {
            borderRadius: '7.5px'
        }
    },
    drawerMenuPadding: {
        paddingLeft: '9px !important',
        paddingRight: '9px !important'
    },
    drawerMenuIcon: {
        marginLeft: '-9px',
        marginBottom: '3px',
        minWidth: '33px !important'
    },
    mobileHeader: {
        [theme.breakpoints.down(1100)]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto 0',
            padding: '0 16.2px'
        },
        [theme.breakpoints.up(1100)]: {
            display: 'none',
        },
    },
    headerContainer: {
        zIndex: `${theme.zIndex.drawer + 1} !important`,
        backgroundColor: theme.palette.type === 'dark' ? '#242526 !important' : '#fff !important',
        boxShadow: theme.palette.type === 'dark' ? '0px 0px 3px 0px rgba(231, 231, 231, 0.24)' : '0px 0px 3px 0px rgba(0, 0, 0, 0.15)',
        minHeight: '56.1px',
    },
    desktopHeader: {
        [theme.breakpoints.down(1100)]: {
            display: 'none',
        },
        [theme.breakpoints.up(1100)]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto 0',
            padding: '0 16.2px',
        },
    },
    inlineDisplay: {
        display: 'inline'
    },
    removeBulletUlList: {
        listStyleType: 'none',
        padding: '0',
        margin: '0'
    },
    menuLink: {
        textDecoration: 'none',
        padding: '0 15px',
        color: theme.palette.type === 'dark' ? '#f2f2f2' : '#222',
        cursor: 'pointer',
        fontSize: '15px',

        '&:hover': {
            color: '#1877f2',
            transition: '0.3s'
        }
    },
    menuIcon: {
        width: '33px', height: '33px', color: '#65676b'
    },
    footerLink: {
        textDecoration: 'none',
        padding: '0 9px 0 0',
        color: theme.palette.type === 'dark' ? '#f2f2f2' : '#737373',
        cursor: 'pointer',
        fontSize: '12px'
    },
    headerStartNowButton: {
        color: 'white',
        padding: '9px 23.1px',

        '&:hover': {
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
        }
    },
    footerContainer: {
        padding: '21px 0', 
        backgroundColor: theme.palette.type === 'dark' ? '#242526' : '#fff'
    },
    footerText: {
        fontSize: '12px', color: '#737373'
    },
    copyrightContainer: {
        padding: '15px 0'
    },
    footerCurve: {
        borderBottom: '1px solid #dddfe2', fontSize: '1px', height: '8.1px', marginBottom: '8.1px', width: '99%'
    },
    goldenTopPadding: {
        padding: '16.2px 0 0 0'
    },
    contactContainerWidth: {
        maxWidth: '750px'
    },
    loginContainerWidth: {
        maxWidth: '501px'
    },
    hoverElevationCapitalizeButton: {
        padding: '9px 23.1px',
        textTransform: 'none',

        '&:hover': {
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
        }
    },
    removeTextTransform: {
        textTransform: 'none',
    },
    buttonLoadingMargin: {
        marginLeft: '9px'
    },
    textDividerContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        
        '&::before': {
            content: '""',
            flex: 1,
            borderBottom: theme.palette.type === 'dark' ? '1px solid #5e5e5e' : '1px solid #dadce0',
        },
        '&::after': {
            content: '""',
            flex: 1,
            borderBottom: theme.palette.type === 'dark' ? '1px solid #5e5e5e' : '1px solid #dadce0',
        },
    },
    textDivider: {
        color: theme.palette.type === 'dark' ? '#5e5e5e' : '#dadce0',
        margin: '0 6px'
    },
    landingPageMainContainerSize: {
        maxWidth: '1100px',
    },
    landingPageMainFlexAlign: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '60vh'
    },
    landingPageMainCanvasContainer: {
        position: 'absolute', height: '100%', width: '100%', top: 0, left: 0, right: 0,
        display: theme.palette.type === 'dark' ? 'none' : 'inherit'
    },
    landingPageMainCanvas: {
        width: '100%',
        transform: 'skewY(-12deg)',
        marginTop: '-15em',
        height: '675px',
        [theme.breakpoints.down(750)]: {
            height: '615px'
        },
        [theme.breakpoints.down(360)]: {
            height: '555px'
        },
    },
    landingPageMainContainer: {
        background: theme.palette.type === 'dark' ? '#18191a' : '#fff',
        padding: '0 0 12em 0'
    },
    caseStudyMainContainer: {
        background: theme.palette.type === 'dark' ? '#18191a' : '#f0f2f5',
        padding: '0 0 9em 0'
    },
    landingPageMainTextContainer: {
        position: 'relative',
        zIndex: 2,
        transition: '1.2s',
        [theme.breakpoints.up(750)]: {
            padding: '12em 0 0 0',
        },
        [theme.breakpoints.down(750)]: {
            padding: '6em 0 0 0',
        },
    },
    landingPageMainText: {
        padding: '0',
        margin: '0',
        [theme.breakpoints.up(1100)]: {
            fontSize: '6.3em',
        },
        [theme.breakpoints.down(1100)]: {
            fontSize: '6.6em'
        },
        [theme.breakpoints.down(654)]: {
            fontSize: '5.4em'
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '4.5em'
        },
        [theme.breakpoints.down(360)]: {
            fontSize: '3.6em'
        },
        display: 'block', fontWeight: 'bold', lineHeight: '1.05'
    },
    landingPageMainSubtextContainer: {
        padding: '30px 0'
    },
    landingPageMainSubtext: {
        display: 'block', lineHeight: '1.50',
        [theme.breakpoints.up(750)]: {
            fontSize: '1.29em',
        },
        [theme.breakpoints.down(750)]: {
            fontSize: '1.11em',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '1.02em',
        },
    },
    caseStudyMainSubtext: {
        display: 'block', lineHeight: '1.50',
        fontStyle: 'italic',
        [theme.breakpoints.up(750)]: {
            fontSize: '1.29em',
        },
        [theme.breakpoints.down(750)]: {
            fontSize: '1.11em',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '1.02em',
        },
    },
    landingPageMainImage: {
        position: 'absolute', top: 0, right: 0, marginTop: '9em', transition: '1.2s',
        [theme.breakpoints.up(1599)]: {
            marginRight: '15em',
        },
        [theme.breakpoints.down(1100)]: {
            display: 'none'
        },
    },
    defaultButtonNoPadding: {
        padding: '0'
    },
    paddingTop6emDisplayGrid4Columns: {
        padding: '6em 0 0 0', display: 'grid',
        [theme.breakpoints.up(750)]: {
            gridTemplateColumns: 'repeat(4,1fr)'
        },
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: 'repeat(2,1fr)'
        },
    },
    padding1x5emDisplayFlexAlignCenter: {
        padding: '1.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '1px dashed #e0e0e0', borderRight: '1px dashed #e0e0e0'
    },
    padding1x5emDisplayFlexAlignCenterExtraBorder: {
        padding: '3em 1.5em 1.5em 1.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '9px', borderLeft: '1px dashed #e0e0e0', borderRight: '1px dashed #e0e0e0'
    },
    paddingTop3em: {
        padding: '3em 0 0 0' 
    },
    fontSize1x35emColorBlueBold: {
        fontSize: '1.35em', color: '#1877f2', fontWeight: '600'
    },
    paddingTop2x7em: {
        padding: '2.7em 0 0 0'
    },
    paddingBottom2x7em: {
        padding: '0 0 2.7em 0'
    },
    fontSize38x1pxBold: {
        fontWeight: '600',
        [theme.breakpoints.up(870)]: {
            fontSize: '38.1px', 
        },
        [theme.breakpoints.down(870)]: {
            fontSize: '32.4px',
        },
        [theme.breakpoints.down(654)]: {
            fontSize: '24.6px',
        },
    },
    paddingTop2x7emDisplayGridTwoColumnsGap3em: {
        padding: '2.7em 0 0 0', display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '3em'
    },
    paddingTop2x7emDisplayGridTwoColumnsGap3emScaleDisplayBlock: {
        [theme.breakpoints.up(750)]: {
            display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '3em'
        },
        [theme.breakpoints.down(750)]: {
            display: 'block'
        },
        padding: '2.7em 0 0 0',
    },
    paddingTopBottom2x7em: {
        padding: '2.7em 0'
    },
    paddingTop2x7emBottom1x2em: {
        padding: '2.7em 0 1.2em 0'
    },
    gradientDeepBlueBackgroundSkewed: {
        background: 'radial-gradient(circle, rgba(7,38,64,0.9724264705882353) 78%, rgba(3,37,63,1) 100%)',
        transform: 'skewY(-6deg)',
    },
    skew6degPaddingTopBottom15em: {
        transform: 'skewY(6deg)', padding: '15em 0'
    },
    displayGridTwoColumns: {
        [theme.breakpoints.up(975)]: {
            display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '3em'
        },
        [theme.breakpoints.down(975)]: {
            display: 'block'
        },
    },
    secondLayoutGridImage: {
        [theme.breakpoints.up(975)]: {
            display: 'inherit'
        },
        [theme.breakpoints.down(975)]: {
            display: 'none'
        },
    },
    fontSize38x1pxBoldWhite: {
        fontWeight: '600', color: '#fff', display: 'block',
        [theme.breakpoints.up(450)]: {
            fontSize: '38.1px', 
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '27.9px', 
        },
    },
    fontSize18x1pxColorGray: {
        color: '#adbdcc', display: 'block',
        [theme.breakpoints.up(450)]: {
            fontSize: '18.1px',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '13.5px',
        },
    },
    mainColorFontSize42px: {
        color: '#1877f2',
        [theme.breakpoints.up(450)]: {
            fontSize: '42px',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '36px',
        },
    },
    paddingTop0x75em: {
        padding: '0.75em 0 0 0'
    },
    paddingTopBottom1x2em: {
        padding: '1.2em 0'
    },
    paddingTop1x2em: {
        padding: '1.2em 0 0 0'
    },
    colorWhiteFontSize15x6pxBoldBorderLeftBluePaddingLeft0x9em: {
        color: '#fff', fontWeight: '600', borderLeft: '2.1px solid #1877f2', paddingLeft: '0.9em',
        [theme.breakpoints.up(450)]: {
            fontSize: '15.6px',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '13.8px',
        },
    },
    fontSize14x4pxColorGray: {
        color: '#adbdcc', display: 'block',
        [theme.breakpoints.up(450)]: {
            fontSize: '14.4px',
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '12.6px',
        },
    },
    backgroundWhiteMarginTopMinus15em: {
        backgroundColor: theme.palette.type === 'dark' ? '#18191a' : '#fff',
        marginTop: '-15em'
    },
    paddingTop30emBottom12em: {
        padding: '30em 0 12em 0'
    },
    fontSize18x1pxColorGreenBold: {
        fontSize: '18.1px', color: '#63be09',
        fontWeight: '600'
    },
    fontSize38x1px: {
        [theme.breakpoints.up(870)]: {
            fontSize: '38.1px', 
        },
        [theme.breakpoints.down(870)]: {
            fontSize: '32.4px',
        },
        [theme.breakpoints.down(654)]: {
            fontSize: '24.6px',
        },
        display: 'block'   
    },
    paddingTop2x7emDisplayGridFourColumnsGap3em: {
        [theme.breakpoints.up(750)]: {
            gridTemplateColumns: 'repeat(4,1fr)'
        },
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: 'repeat(2,1fr)'
        },
        padding: '2.7em 0 0 0', display: 'grid', gap: '3em'
    },
    fontSize15x6emBoldBorderLeftBlue: {
        fontWeight: '600', borderLeft: '2.1px solid #1877f2', paddingLeft: '0.9em',
        [theme.breakpoints.up(450)]: {
            fontSize: '15.6px', 
        },
        [theme.breakpoints.down(450)]: {
            fontSize: '13.8px', 
        },  
    },
    paddingTopBottom9em: {
        padding: '9em 0'
    },
    paddingBottom9em: {
        padding: '0 0 9em 0'
    },
    paddingTop2x7emBottom11x4em: {
        padding: '2.7em 0 11.4em 0'
    },
    displayGrid1MainColumn2SideColumnsGap3em: {
        [theme.breakpoints.up(840)]: {
            display: 'grid', gridTemplateColumns: '2fr repeat(2,1fr)', gap: '3em' 
        },
        [theme.breakpoints.down(840)]: {
            display: 'block'
        },
    },
    paddingTop2x1emDown840: {
        [theme.breakpoints.down(840)]: {
            padding: '2.1em 0 0 0'
        },
    },
    fontSize24pxBold: {
        fontSize: '24px', fontWeight: '600'
    },
    paddingTop2x1em: {
        padding: '2.1em 0 0 0'
    },
    fontSize16x5emColorLightDark: {
        fontSize: '16.5px', color: theme.palette.type === 'dark' ? '#fff' : '#425466',
        display: 'block'
    },
    fontSize16x5emColorLightDarkItalic: {
        fontSize: '16.5px', color: theme.palette.type === 'dark' ? '#fff' : '#425466',
        display: 'block',
        fontStyle: 'italic'
    },
    fontSize14x4pxColorLightDark: {
        fontSize: '14.4px', color: theme.palette.type === 'dark' ? '#fff' : '#425466',
        display: 'block' 
    },
    contactContainer: {
        backgroundColor: theme.palette.type === 'dark' ? '#242526' : '#f9f9f9',
        border: theme.palette.type === 'dark' ? '1px solid #5e5e5e' : '1px solid #dadce0',
        borderRadius: '7.5px',
        padding: '36px'
    },
    contactTitle: {
        fontSize: '24px',
        fontWeight: '400',
    },
    goldenTopPadding: {
        padding: '16.2px 0 0 0'
    },
    contactContinueContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto 0',
        padding: '54px 0'
    },
    contactContainerWidth: {
        maxWidth: '750px'
    },
    contactAgreementText: {
        textDecoration: 'none',
        color: theme.palette.type === 'dark' ? '#f2f2f2' : '#737373',
        cursor: 'pointer',
        fontSize: '11.1px'
    },
    contactTextfieldGrid: {
        display: 'grid', gridTemplateColumns: '50% 50%', padding: '16.2px 0 0 0'
    },
    contactImageContainer: {
        [theme.breakpoints.down(960)]: {
            display: 'none'
        },
        [theme.breakpoints.up(960)]: {
            display: 'flex', alignItems: 'center', flexDirection: 'column'
        },
        paddingTop: '1.2em'
    },
    contactLogoContainer: {
        width: '90px'
    },
    contactSuccessContainer: {
        textAlign: 'center', transition: '0.3s', padding: '0 9px' 
    },
    contactSuccessIconContainer: {
        display: 'flex', justifyContent: 'center'
    },
    contactSuccessIcon: {
        fontSize: '60px', color: '#1877f2' 
    },
    contactSuccessSubtext: {
        color: '#1877f2', fontWeight: '300'  
    },
    whiteBackgroundContainer: {
        background: theme.palette.type === 'dark' ? '#18191a' : '#fff',
    },
    whiteBackgroundMarginTopMinus9emPaddingTop6emBottom15emContainer: {
        background: theme.palette.type === 'dark' ? '#18191a' : '#fff',
        marginBottom: '-9em', padding: '6em 0 15em 0'
    },
    textAlignCenterPaddingTopBottom4x5em: {
        textAlign: 'center', padding: '4.5em 0'
    },
    textAlignCenterPaddingTop15emBottom4x5em: {
        textAlign: 'center', padding: '15em 0 4.5em 0'
    },
    displayBlockColorGrayFontSize21pxBold: {
        display: 'block', color: theme.palette.type === 'dark' ? '#fff' : '#32325d', fontSize: '21px', fontWeight: '600'
    },
    displayGridThreeColumnsGap3x45emPaddingBottom4x5em: {
        [theme.breakpoints.up(600)]: {
            display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gap: '3.45em', padding: '0 0 4.5em 0' 
        },
        [theme.breakpoints.down(600)]: {
            display: 'block'
        },
    },
    paddingBottom3emMobile: {
        [theme.breakpoints.down(600)]: {
            padding: '0 0 4.5em 0' 
        },
    },
    displayBlockColorGrayFontSize18px: {
        display: 'block', fontSize: '18px',
        color: theme.palette.type === 'dark' ? '#fff' : '#32325d'
    },
    fontSize12x6px: {
        fontSize: '12.6px'  
    },
    verticalAlignMiddleMarginRight9pxColorGray: {
        verticalAlign: 'middle', marginRight: '9px', color: '#6b7c93'
    },
    colorLightGray: {
        color: theme.palette.type === 'dark' ? '#fff' : '#6b7c93',
    },
    paddingTop9emBottom4x5em: {
        padding: '9em 0 4.5em 0'
    },
    textAlignCenter: {
        textAlign: 'center' 
    },
    fontSize3em: {
        [theme.breakpoints.up(600)]: {
            fontSize: '3em',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '2.1em',
        },
    },
    fontSize2x1em: {
        [theme.breakpoints.up(600)]: {
            fontSize: '2.1em',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '1.65em',
        },
    },
    borderRadius6pxWhiteBackgroundMarginTop3emPadding30pxLightBoxShadow: {
        borderRadius: '6px', margin: '3em 0 0 0', padding: '30px', boxShadow: '0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3)',
        background: theme.palette.type === 'dark' ? '#242526' : '#fff',
    },
    colorGrayFontSize21pxBoldUppercase: {
        fontSize: '21px', fontWeight: '600', textTransform: 'uppercase', display: 'block',
        color: theme.palette.type === 'dark' ? '#fff' : '#32325d',
    },
    paddingTop4x5emBottom6em: {
        padding: '4.5em 0 6em 0' 
    },
    displayGridTwoColumnsGap4x5emPaddingBottom4x5em: {
        [theme.breakpoints.up(600)]: {
            display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '4.5em', padding: '0 0 4.5em 0' 
        },
        [theme.breakpoints.down(600)]: {
            display: 'block'
        },
    },
    verticalAlignMiddleMarginRight9pxColorGrayFontSize33x3px: {
        verticalAlign: 'middle', marginRight: '9px', color: '#63be09', fontSize: '33.3px'
    },
    fontSize18pxBold: {
        fontSize: '18px', fontWeight: '600'  
    },
    paddingTop0x75emPaddingLeft45px: {
        padding: '0.75em 0 0 0', paddingLeft: '45px'
    },
    paddingTop0x75emDisplayBlock: {
        padding: '0.75em 0 0 0', display: 'block' 
    },
    displayGridTwoColumns45pxAuto: {
        display: 'grid', gridTemplateColumns: '45px auto'
    },
    paddingTopBottom51pxBackgroundRadialBlue: {
        padding: '51px 0', background: 'radial-gradient(circle, rgba(24,119,242,1) 0%, rgba(18,81,163,1) 100%)'
    },
    colorWhiteFontSize3em: {
        [theme.breakpoints.up(600)]: {
            fontSize: '3em'
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '2.1em'
        },
        color: '#fff', 
    },
    radialGrayPadding30pxBorderRadius6pxWithLightBoxShadow: {
        background: 'radial-gradient(circle, rgba(57,58,60,1) 0%, rgba(36,37,38,1) 100%)', padding: '30px', borderRadius: '6px', textAlign: 'center', margin: '3em 0px -15em', boxShadow: '0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3)'
    },
    colorWhiteFontSize1x5em: {
        color: '#fff', fontSize: '1.5em' 
    },
    colorWhiteFontSize1x2em: {
        color: '#fff', fontSize: '1.2em' 
    },
    colorWhiteFontSize1x11em: {
        color: '#fff', fontSize: '1.11em' 
    },
    linkClass: {
        color: 'white !important',
        textDecoration: 'none'
    },
    linkNoDecoration: {
        color: 'inherit',
        textDecoration: 'none'
    },
    linkNoDecorationFlex: {
        display: 'flex',
        color: 'inherit',
        textDecoration: 'none'
    },
    creditPaymentContainer: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        gap: '0.3em',

        [theme.breakpoints.down(510)]: {
            display: 'block'
        }
    },
    fileProgressContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gap: '0.9em',
        alignItems: 'center',

        [theme.breakpoints.down(510)]: {
            gridTemplateColumns: 'auto',
        }
    },
    fileProgressInfoContainer: {
        position: 'relative',
        marginTop: '0.6em'
    },
    fileProgressInfoContainerTitle: {
        position: 'absolute',
        color: 'gray',
        left: 0,
        bottom: '3px',

        [theme.breakpoints.down(510)]: {
            bottom: 'auto',
            top: '3px'
        }
    },
    productsGridContainer: {
        display: 'grid', gridTemplateColumns: 'repeat(4, 25%)', gap: '0.75em', paddingTop: '6em',

        [theme.breakpoints.down(1200)]: {
            paddingTop: '16px',
            gridTemplateColumns: 'auto',
        }
    },
    productGridContainer: {
        display: 'grid', gridTemplateColumns: '50% 50%', gap: '3em', padding: '4.5em 0',

        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'auto',
        }
    },
    checkoutGridContainer: {
        display: 'grid', gridTemplateColumns: '50% 50%', gap: '3em', padding: '3em 0',

        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'auto',
        }
    },
    cartButtonsContainer: {
        display: 'flex', justifyContent: 'flex-end', gap: '0.45em',

        [theme.breakpoints.down(1200)]: {
            flexDirection: 'column'
        }
    },
    cartProductTitle: {
        cursor: 'pointer', display: 'flex', alignItems: 'center', width: '420px',
    
        [theme.breakpoints.down(1200)]: {
            width: 'auto',
            padding: '0 0.75em'
        }
    },
    flexRowSGap: {
        display: 'flex',
        gap: '0.3em'
    },
    flexRowLGap: {
        display: 'flex',
        gap: '0.9em'
    },
    einEntitiesContainer: {
        display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', gap: '0.3em', padding: '1.5em 0', margin: '0 auto',
        
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: 'auto',
        }
    },
    einEntityType: {
        display: 'flex', gap: '0.9em', alignItems: 'center',
        
        [theme.breakpoints.down(900)]: {
            flexDirection: 'column',
            alignItems: 'inherit'
        }
    },
    einInfoContainer: {
        display: 'grid', gridTemplateColumns: '30% 70%', alignItems: 'center',
                
        [theme.breakpoints.down(900)]: {
            gridTemplateColumns: 'auto',
            gap: '0.9em'
        }
    }
}));

export default useStyles;

import React from 'react';

import NavMenu from './NavMenu';
import PayNavMenu from './PayNavMenu';
import Footer from './Footer';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import useStyles from '../styles/styles';

import { observer, inject } from 'mobx-react';

import { ToastContainer } from 'react-toastify'

const Theme = createTheme({
    palette: {
        primary: {
            main: '#19a1ff',
        },
        secondary: {
            main: '#e0e0e0'
        }
    },
    overrides: {
        MuiSnackbar: {
            anchorOriginBottomCenter: {
                bottom: '90px !important',
            },
        },
        MuiListItem: {
            gutters: {
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingBottom: '9px'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '36px',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.06)',
                borderRadius: '6px',
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.06)',
                    borderRadius: '6px',
                },
            },
        },
        MuiSvgIcon: {
            colorSecondary: {
                color: '#fff'
            },
            root: {
                color: '#b3b0b0',
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#fff'
            },
        },
        MuiAlert: {
            message: {
                wordBreak: 'break-all'
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '7.5px',
            },
        },
    },
});

export default inject('alertStore')(observer((props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={Theme}>
            <ToastContainer
                position="top-right"
                autoClose={6000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                debug
                pauseOnHover
            />

            <div className={classes.base}>
                {
                    props.alertStore.alertState.show &&
                    <Alert severity={props.alertStore.alertState.state}>{props.alertStore.alertState.message}</Alert>
                }

                {
                    props.alertStore.dashboardAlerts.map((el, ind) => {
                        return <Alert key={ind} severity={el.state}>{el.message}</Alert>
                    })
                }

                {
                        window.location.href.includes('/epay/') ?
                            <div />
                            :
                            window.location.href.includes('/form') ?
                                <div />
                                :
                                window.location.href.includes('/pay/') === false ?
                                    <NavMenu />
                                    :
                                    <PayNavMenu />
                }
                {props.children}
                {
                    window.location.href.includes('/pay/') === false &&
                    window.location.href.includes('/ein/') === false &&
                    <Footer />
                }
            </div>
        </ThemeProvider>
    );
}));

import React from 'react';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import DesktopMenu from '../components/DesktopMenu';
import IndividualEIN from '../components/IndividualEIN';
import LLCEIN from '../components/LLCEIN';
import DeceasedEIN from '../components/DeceasedEIN';
import TrustEIN from '../components/TrustEIN';
import NonProfitEIN from '../components/NonProfitEIN';
import CorporationEIN from '../components/CorporationEIN';
import SCorporationEIN from '../components/SCorporationEIN';
import PartnershipEIN from '../components/PartnershipEIN';
import PersonalServiceEIN from '../components/PersonalServiceEIN';
import ChurchEIN from '../components/ChurchEIN';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

export default inject('alertStore', 'formStore')(observer((props) => {
    const params = useParams();

    const [loadingState, setLoadingState] = React.useState(true);
    const [einApplicationsState, setEinApplicationsState] = React.useState([]);

    React.useEffect(() => {
        const token = params.token
        props.formStore.setEinState('token', token)
        //console.log(toJS(props.formStore.einState))
        fetch(`${process.env.API_URL}/ein-submit/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, type: 'all' })
        }).then(res => {
            if (res.status === 200) return res.json();
        }).then(res => {
            setEinApplicationsState(res.detail);
            setLoadingState(false);
            window.scrollTo(0, 0);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const classes = useStyles();

    const viewEinApplication = (el) => {
        for (const key of Object.keys(el)) {
            if (key !== 'entity') {
                props.formStore.setEinState(key, el[key]);
            }
        }

        props.formStore.setEinState('entity', el.entity);
    };

    return (


        <div className={classes.homeLayoutGrid} style={{ padding: '15px' }}>
            {
                props.formStore.einState.entity === '' &&
                <div className={classes.roundDiv}>
                    <span style={{ fontSize: '1.5em' }}>
                        Submitted Applications
                    </span>
                    <div className={classes.roundDiv} style={{ display: 'flex', gap: '0.45em', flexDirection: 'column' }}>
                        {
                            loadingState ?
                                <LinearProgress />
                                :
                                einApplicationsState.length === 0 ?
                                    <span>None found</span>
                                    :
                                    einApplicationsState.map(el => {
                                        return (
                                            <div key={el.id} onClick={() => viewEinApplication(el)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{el.application_name}</span>
                                                <span>Success</span>
                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>
            }

            <div style={{ margin: '0.6em 0' }} className={classes.roundDiv}>
                {
                    props.formStore.einState.entity === '' ?
                        <span style={{ fontSize: '1.5em' }}>
                            Apply for your Federal Tax ID / EIN
                        </span>
                        :
                        <div className={classes.einEntityType}>
                            <span style={{ fontSize: '1.5em' }}>{props.formStore.einState.entity} EIN Application</span>
                            <Button onClick={() => props.formStore.setEinState('entity', '')} variant="outlined" color="primary">Change Entity</Button>
                        </div>
                }

                {
                    props.formStore.einState.entity === '' &&
                    <div className={classes.roundDiv} style={{ marginTop: '1.2em' }}>
                        <span style={{ fontSize: '1.5em' }}>Choose your entity type</span>
                        <div className={classes.einEntitiesContainer}>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Individual / Sole Proprietor')}>
                                <PersonIcon style={{ color: '#19a1ff' }} />
                                <span>Individual / Sole Proprietor</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Limited Liability Company')}>
                                <GroupIcon style={{ color: '#19a1ff' }} />
                                <span>Limited Liability Company</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Estate of a Deceased Individual')}>
                                <DescriptionIcon style={{ color: '#19a1ff' }} />
                                <span>Estate of a Deceased Individual</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Trust')}>
                                <FolderIcon style={{ color: '#19a1ff' }} />
                                <span>Trust</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Non-Profit Organization')}>
                                <FavoriteBorderIcon style={{ color: '#19a1ff' }} />
                                <span>Non-Profit Organization</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Corporation')}>
                                <BusinessIcon style={{ color: '#19a1ff' }} />
                                <span>Corporation</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'S-Corporation')}>
                                <ApartmentIcon style={{ color: '#19a1ff' }} />
                                <span>S-Corporation</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Partnership')}>
                                <PersonAddIcon style={{ color: '#19a1ff' }} />
                                <span>Partnership</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Personal Service Corporation')}>
                                <BusinessCenterIcon style={{ color: '#19a1ff' }} />
                                <span>Personal Service Corporation</span>
                            </div>
                            <div className={classes.roundDivHover} onClick={() => props.formStore.setEinState('entity', 'Church Organization')}>
                                <LocationCityIcon style={{ color: '#19a1ff' }} />
                                <span>Church Organization</span>
                            </div>
                        </div>
                    </div>
                }

                {
                    props.formStore.einState.entity === 'Individual / Sole Proprietor' &&
                    <IndividualEIN />
                }

                {
                    props.formStore.einState.entity === 'Limited Liability Company' &&
                    <LLCEIN />
                }

                {
                    props.formStore.einState.entity === 'Estate of a Deceased Individual' &&
                    <DeceasedEIN />
                }

                {
                    props.formStore.einState.entity === 'Trust' &&
                    <TrustEIN />
                }

                {
                    props.formStore.einState.entity === 'Non-Profit Organization' &&
                    <NonProfitEIN />
                }

                {
                    props.formStore.einState.entity === 'Corporation' &&
                    <CorporationEIN />
                }

                {
                    props.formStore.einState.entity === 'S-Corporation' &&
                    <SCorporationEIN />
                }

                {
                    props.formStore.einState.entity === 'Partnership' &&
                    <PartnershipEIN />
                }

                {
                    props.formStore.einState.entity === 'Personal Service Corporation' &&
                    <PersonalServiceEIN />
                }

                {
                    props.formStore.einState.entity === 'Church Organization' &&
                    <ChurchEIN />
                }
            </div>
        </div>
    );
}))

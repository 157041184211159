import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from "@sentry/react";

import App from './App';
import { Provider } from 'mobx-react';
//import registerServiceWorker from './registerServiceWorker';

import { UiStateStore } from './stores/uiStateStore';
import { ErrorStore } from './stores/errorStore';
import { FormStore } from './stores/formStore';
import { AlertStore } from './stores/alertStore';

import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import './style.css';

import('preline')

const stores = {
    uiStateStore: new UiStateStore(),
    errorStore: new ErrorStore(),
    formStore: new FormStore(),
    alertStore: new AlertStore()
}

Sentry.init({
    dsn: "https://ee71e5c04805a8faf9aaa3be67a7f192@o1056698.ingest.sentry.io/4505985934819328",
    tunnel: process.env.API_URL + "/tunnel/",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/urchin-app-7lrhc\.ondigitalocean\.app/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider {...stores}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

//registerServiceWorker();

import React from 'react';
import { NavLink } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TimelineIcon from '@material-ui/icons/Timeline';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FileCopy from '@material-ui/icons/FileCopy';
import Label from '@material-ui/icons/Label';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SubjectIcon from '@material-ui/icons/Subject';
import HearingIcon from '@material-ui/icons/Hearing';
import GroupIcon from '@material-ui/icons/Group';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ComputerIcon from '@material-ui/icons/Computer';
import StoreIcon from '@material-ui/icons/Store';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import Chip from '@material-ui/core/Chip';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';

import useStyles from '../styles/styles';

import { getCookie } from '../common/utils';

const DesktopMenu = (props) => {
    const [permissionsState, setPermissionState] = React.useState([]);

    React.useEffect(() => {
        const permissions = getCookie('E7WDgBTb2EbM3x2');
        if (permissions !== undefined) {
            setPermissionState(permissions.split(','));
        }
    }, []);


    const classes = useStyles();

    return (
        <React.Fragment>

            <List component="nav">

                <span>Business Operation</span>
                <NavLink to="/form-company" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/form-company')}>
                        <ListItemIcon>
                            <BusinessIcon style={{ color: '#0aae0a' }} />
                        </ListItemIcon>
                        <ListItemText primary="Form your company" style={{ color: '#0aae0a' }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/dissolve-company" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/dissolve-company')}>
                        <ListItemIcon>
                            <DomainDisabledIcon style={{ color: 'red' }} />
                        </ListItemIcon>
                        <ListItemText primary="Dissolve your company" style={{ color: 'red' }} />
                    </ListItem>
                </NavLink>

                <div style={{ marginBottom: '1.95em' }} />

                <span>Business Management</span>
                <NavLink to="/reports" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/reports')}>
                        <ListItemIcon>
                            <FileCopy style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="File Report" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/labor" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/labor')}>
                        <ListItemIcon>
                            <Label style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="Labor Law" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/certificate" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/certificate')}>
                        <ListItemIcon>
                            <PlaylistAddCheckIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="Obtain Certificate of Status" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/ein" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/ein')}>
                        <ListItemIcon>
                            <InsertDriveFileIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="File EIN" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/orders" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/orders')}>
                        <ListItemIcon>
                            <FormatListNumberedIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="All Documents" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <div style={{ marginBottom: '1.95em' }} />


                <span>Business Compliance</span>
                <NavLink to="/products" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/products')}>
                        <ListItemIcon>
                            <ShoppingBasketIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="View Products" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/cart" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/cart')}>
                        <ListItemIcon>
                            <ShoppingCartIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="Cart" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/account" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button selected={window.location.pathname.includes('/account')}>
                        <ListItemIcon>
                            <AccountCircleIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="Account" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

                <NavLink to="/logout" exact activeClassName={classes.homeActiveNavLink} className={classes.homeNavLink}>
                    <ListItem button>
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: '#19a1ff' }} />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" style={{ color: props.fontColor }} />
                    </ListItem>
                </NavLink>

            </List>
        </React.Fragment>
    )
};

export default DesktopMenu;

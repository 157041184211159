import React from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';

import Skeleton from 'react-loading-skeleton';

import ReactHtmlParser from 'react-html-parser';

import useStyles from '../styles/styles';

import { observer, inject } from 'mobx-react';

const Product = inject('uiStateStore', 'alertStore')(observer((props) => {
    const navigate = useNavigate();

    const [productState, setProductState] = React.useState({
        name: '',
        description: '',
        price: '',
        discount_price: '',
        img: ''
    });

    React.useEffect(() => {
        fetch(`${process.env.API_URL}/products/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({ type: 'one', pk: props.match.params.id })
        }).then(res => res.json()).then(res => {
            if (res.detail.data !== undefined) {
                setProductState(res.detail.data);
                //setTimeout(() => setProductState(res.detail.data), 1500);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const classes = useStyles();

    const addToCart = () => {
        if (props.uiStateStore.uiState.cart.length === 0) {
            props.uiStateStore.addProduct(productState);
            navigate('/cart');
        } else {
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'info');
            props.alertStore.setAlertState('message', 'Only 1 product allowed inside cart due to payment limitations. Please checkout and continue shopping other products.');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 7500);
        }
    };

    return (
        <div className={classes.whiteBackgroundContainer}>
            <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                <div className={classes.productGridContainer}>
                    {
                        productState.name === '' ?
                        <>
                            <div>
                                <Skeleton height={600} />
                            </div>
                            <div>
                                <Skeleton height={75} />
                                <Skeleton height={45} />
                                <Skeleton height={45} />
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <img src={productState.img} width="100%" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5em' }}>
                                <span style={{ fontSize: '2.7em', lineHeight: '1' }}>
                                    {productState.name}
                                </span>
                                <div>
                                    {
                                        productState.discount_price !== 0 &&
                                        <span style={{ color: 'gray', fontSize: '1.5em', textDecoration: 'line-through', textDecorationThickness: '3px', marginRight: '0.3em' }}>${productState.discount_price}</span>
                                    }
                                    <span style={{ color: '#19a1ff', fontSize: '1.5em' }}>${productState.price}</span>
                                </div>
                                <div style={{ paddingTop: '1.8em' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        classes={{ root: classes.headerStartNowButton }}
                                        onClick={addToCart}>
                                        Add To Cart
                                    </Button>
                                </div>
                                <div style={{ padding: '1.8em 0' }}>
                                    {ReactHtmlParser(productState.description)}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Container>
        </div>
    )
}));

export default Product;

import React from 'react';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';

import DesktopMenu from './DesktopMenu';

import { observer, inject } from 'mobx-react';

import useStyles from '../styles/styles';

export default inject('uiStateStore')(observer((props) => {
    const classes = useStyles();

    return (
        <header>
            <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
                <div className={classes.navMenuContainer}>
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <h1 className={classes.title}>
                            {props.uiStateStore.uiState.navMenuTitle}
                        </h1>
                    </Toolbar>
                </div>
            </AppBar>
        </header>
    );
}));

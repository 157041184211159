import { observable, action } from 'mobx';

export class FormStore {
    formState: any = observable({
        password: '',
        email: ''
    });

    einState: any = observable({
        token: '',
        application_name: '',
        entity: '',
        church_name: '',
        partnership_name: '',
        corporation_name: '',
        nonprofit_name: '',
        organization_type: '',
        trust_name: '',
        trust_type: '',
        trustee_first_name: '',
        trustee_middle_name: '',
        trustee_last_name: '',
        trustee_title: '',
        trust_funded_month: '',
        trust_funded_day: '',
        trust_funded_year: '',
        deceased_first_name: '',
        deceased_middle_name: '',
        deceased_last_name: '',
        deceased_ssn: '',
        deceased_verify_ssn: '',
        death_month: '',
        death_day: '',
        death_year: '',
        llc_name: '',
        dba: '',
        num_of_members: '',
        taxation_type: '',
        origin_country: 'United States',
        origin_state: '',
        trade_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        ssn: '',
        verify_ssn: '',
        title: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        county: '',
        diff_mailing_address: false,
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zipcode: '',
        applying_reason: '',
        primary_activity: '',
        specific_products: '',
        highway_vehicle_55kpounds: false,
        involve_gambling: false,
        manufacture_alcohol_tobacco_firearms: false,
        federal_excise_taxes: false,
        previous_ein: false,
        old_ein: '',
        less_than_4k_wages: false,
        new_employee_within_year: false,
        new_employee_first_wage_month: '',
        new_employee_first_wage_day: '',
        new_employee_first_wage_year: '',
        new_employee_amount_agricultural: '',
        new_employee_amount_household: '',
        new_employee_amount_other: '',
        business_acquired_month: '',
        business_acquired_day: '',
        business_acquired_year: '',
        accounting_year_end: ''
    });

    formCompanyState: any = observable({
        application_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        name: '',
        type: 'LLC',
        category: '',
        description: '',
        only_owner: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        county: '',
        diff_mailing_address: false,
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zipcode: '',
        ssn: '',
        verify_ssn: '',
    });

    dissolveCompanyState: any = observable({
        application_name: '',
        entity_type: 'LLC',
        name: '',
        formation_state: '',
        sos_number: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        county: '',
        diff_mailing_address: false,
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zipcode: '',
        agent_name: '',
        agent_address: '',
        agent_city: '',
        agent_zipcode: '',
        agent_county: '',
        managers: [],
        members: []
    });

    setFormCompanyState = action((name: any, value: any) => {
        this.formCompanyState[name] = value;
    });

    setDissolveCompanyState = action((name: any, value: any) => {
        this.dissolveCompanyState[name] = value;
    });

    addDissolveCompanyManager = action(() => {
        this.dissolveCompanyState.managers.push({
            full_name: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
        });
    });

    deleteDissolveCompanyManager = action((index: any) => {
        this.dissolveCompanyState.managers.splice(index, 1);
    });

    changeDissolveCompanyManager = action((index: any, name: any, value: any) => {
        this.dissolveCompanyState.managers[index][name] = value;
    });

    addDissolveCompanyMember = action(() => {
        this.dissolveCompanyState.members.push({
            full_name: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
        });
    });

    deleteDissolveCompanyMember = action((index: any) => {
        this.dissolveCompanyState.members.splice(index, 1);
    });

    changeDissolveCompanyMember = action((index: any, name: any, value: any) => {
        this.dissolveCompanyState.members[index][name] = value;
    });

    setEinState = action((name: any, value: any) => {
        this.einState[name] = value;
    });

    newEinState = action((value: any) => {
        this.einState = value;
    });

    refreshEinState = action(() => {
        this.einState = {
            application_name: '',
            entity: '',
            church_name: '',
            partnership_name: '',
            corporation_name: '',
            nonprofit_name: '',
            organization_type: '',
            trust_name: '',
            trust_type: '',
            trustee_first_name: '',
            trustee_middle_name: '',
            trustee_last_name: '',
            trustee_title: '',
            trust_funded_month: '',
            trust_funded_day: '',
            trust_funded_year: '',
            deceased_first_name: '',
            deceased_middle_name: '',
            deceased_last_name: '',
            deceased_ssn: '',
            deceased_verify_ssn: '',
            death_month: '',
            death_day: '',
            death_year: '',
            llc_name: '',
            dba: '',
            num_of_members: '',
            taxation_type: '',
            origin_country: 'United States',
            origin_state: '',
            trade_name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            ssn: '',
            verify_ssn: '',
            title: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            county: '',
            diff_mailing_address: false,
            mailing_address: '',
            mailing_city: '',
            mailing_state: '',
            mailing_zipcode: '',
            applying_reason: '',
            primary_activity: '',
            specific_products: '',
            highway_vehicle_55kpounds: false,
            involve_gambling: false,
            manufacture_alcohol_tobacco_firearms: false,
            federal_excise_taxes: false,
            previous_ein: false,
            old_ein: '',
            less_than_4k_wages: false,
            new_employee_within_year: false,
            new_employee_first_wage_month: '',
            new_employee_first_wage_day: '',
            new_employee_first_wage_year: '',
            new_employee_amount_agricultural: '',
            new_employee_amount_household: '',
            new_employee_amount_other: '',
            business_acquired_month: '',
            business_acquired_day: '',
            business_acquired_year: '',
            accounting_year_end: ''
        };
    });

    setFormState = action((name: any, value: any) => {
        this.formState[name] = value;
    });

    clearFormState = action(() => {
        this.formState = {
            password: '',
            email: ''
        }
    });
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DesktopMenu from '../components/DesktopMenu';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

const DashboardLayout = (props) => {
    if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    } else {
        return (
            <div />
        )
    }
};

export default inject('alertStore', 'formStore')(observer((props) => {
    const navigate = useNavigate();

    const [loadingState, setLoadingState] = React.useState(true);
    const [formCompanyApplicationsState, setFormCompanyApplicationsState] = React.useState([]);
    const [statusState, setStatusState] = React.useState(0);

    React.useEffect(() => {
        if (!/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            navigate('/login');
        } else {
            fetch(`${process.env.API_URL}/notifications/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                }
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail !== undefined) {
                    for (const notification of res.detail) {
                        props.alertStore.pushDashboardAlert({
                            state: notification.state,
                            message: notification.notification
                        });
                    }

                    setTimeout(() => {
                        props.alertStore.clearDashboardAlerts();
                    }, 30000);
                }
            }).catch(err => {
                console.log(err);
            });

            fetch(`${process.env.API_URL}/dissolve-company-control/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                },
                body: JSON.stringify({ type: 'all' })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                setFormCompanyApplicationsState(res.detail);
                setLoadingState(false);
                window.scrollTo(0, 0);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    const classes = useStyles();

    const viewApplication = (el) => {
        for (const key of Object.keys(el)) {
            props.formStore.setDissolveCompanyState(key, el[key]);
        }

        setStatusState(1);
    };

    const submitApplication = () => {
        if (props.formStore.dissolveCompanyState.name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.entity_type === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill entity type');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }
        
        else if (props.formStore.dissolveCompanyState.formation_state === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill formation state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.sos_number === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill sos number');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.address === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.city === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.state === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.zipcode === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.county === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill county');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.mailing_address === '' && props.formStore.dissolveCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.mailing_city === '' && props.formStore.dissolveCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.mailing_state === '' && props.formStore.dissolveCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.mailing_zipcode === '' && props.formStore.dissolveCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.agent_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill agent name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.agent_address === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill agent address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.agent_city === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill agent city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.agent_zipcode === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill agent zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.agent_county === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill agent county');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.managers.length === 0) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please add managers');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.dissolveCompanyState.members.length === 0) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please add members');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else {
            props.formStore.setDissolveCompanyState('application_name', `${props.formStore.dissolveCompanyState.name.toUpperCase()}-${Math.floor(Date.now() / 1000)}`);
            setLoadingState(true);

            fetch(`${process.env.API_URL}/dissolve-company-control/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                },
                body: JSON.stringify({ type: 'new', ...toJS(props.formStore.dissolveCompanyState) })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail === 'Success') {
                    window.location.reload();
                } else {
                    window.scrollTo(0, 0);
                    props.alertStore.setAlertState('show', true);
                    props.alertStore.setAlertState('state', 'error');
                    props.alertStore.setAlertState('message', res.detail.invalid);
        
                    setTimeout(() => {
                        props.alertStore.setAlertState('show', false);
                        props.alertStore.setAlertState('message', '');
                    }, 4500);
                }
                setLoadingState(false);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    return (
        <DashboardLayout>

            <div className={classes.wrapper}>
                <div className={classes.homeGrid}>

                    <div className={classes.homeMenu}>

                        <DesktopMenu fontColor="black" />

                    </div>

                    <div className={classes.homeLayoutGrid} style={{ padding: '15px' }}>

                        {
                            statusState === 0 &&
                            <>
                                <div className={classes.roundDiv}>
                                    <div className={classes.einEntityType}>
                                        <span style={{ fontSize: '1.5em' }}>Dissolve your company in 10 minutes</span>
                                        <Button onClick={() => setStatusState(1)} style={{ color: '#fff' }} variant="contained" color="primary">Get Started</Button>
                                    </div>
                                </div>

                                <div className={classes.roundDiv}>
                                        <span style={{ fontSize: '1.5em' }}>
                                            Submitted Applications
                                        </span>
                                        <div className={classes.roundDiv} style={{ display: 'flex', gap: '0.45em', flexDirection: 'column', marginTop: '1.2em' }}>
                                            {
                                                loadingState ?
                                                <LinearProgress />
                                                :
                                                formCompanyApplicationsState.length === 0 ?
                                                <span>None found</span>
                                                :
                                                formCompanyApplicationsState.map(el => {
                                                    return (
                                                        <div key={el.id} onClick={() => viewApplication(el)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{el.application_name}</span>
                                                            <span>{el.status}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                </div>
                            </>
                        }

                        {
                            statusState === 1 &&
                            <div className={classes.roundDiv}>

                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Business Information</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>Entity Type</InputLabel>
                                                <Select
                                                    value={props.formStore.dissolveCompanyState.entity_type}
                                                    onChange={(e) => props.formStore.setDissolveCompanyState('entity_type', e.target.value)}
                                                    label="Entity Type"
                                                >
                                                    <MenuItem value="LLC">LLC</MenuItem>
                                                    <MenuItem value="Corporation">Corporation</MenuItem>
                                                    <MenuItem value="Limited Partnership">Limited Partnership</MenuItem>
                                                    <MenuItem value="Limited Liability Partnership">Limited Liability Partnership</MenuItem>
                                                    <MenuItem value="Non-Profit Corporation">Non-Profit Corporation</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField value={props.formStore.dissolveCompanyState.name} onChange={(e) => props.formStore.setDissolveCompanyState('name', e.target.value)} variant="outlined" placeholder={`${props.formStore.dissolveCompanyState.entity_type} Legal Name`} label={`${props.formStore.dissolveCompanyState.entity_type} Legal Name`} fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>State of Formation</InputLabel>
                                                <Select
                                                    value={props.formStore.dissolveCompanyState.formation_state}
                                                    onChange={(e) => props.formStore.setDissolveCompanyState('formation_state', e.target.value)}
                                                    label="State of Formation"
                                                >   
                                                    <MenuItem value="AL">AL</MenuItem>
                                                    <MenuItem value="AK">AK</MenuItem>
                                                    <MenuItem value="AZ">AZ</MenuItem>
                                                    <MenuItem value="AR">AR</MenuItem>
                                                    <MenuItem value="CA">CA</MenuItem>
                                                    <MenuItem value="CO">CO</MenuItem>
                                                    <MenuItem value="CT">CT</MenuItem>
                                                    <MenuItem value="DE">DE</MenuItem>
                                                    <MenuItem value="FL">FL</MenuItem>
                                                    <MenuItem value="GA">GA</MenuItem>
                                                    <MenuItem value="HI">HI</MenuItem>
                                                    <MenuItem value="ID">ID</MenuItem>
                                                    <MenuItem value="IL">IL</MenuItem>
                                                    <MenuItem value="IN">IN</MenuItem>
                                                    <MenuItem value="IA">IA</MenuItem>
                                                    <MenuItem value="KS">KS</MenuItem>
                                                    <MenuItem value="KY">KY</MenuItem>
                                                    <MenuItem value="LA">LA</MenuItem>
                                                    <MenuItem value="ME">ME</MenuItem>
                                                    <MenuItem value="MD">MD</MenuItem>
                                                    <MenuItem value="MA">MA</MenuItem>
                                                    <MenuItem value="MI">MI</MenuItem>
                                                    <MenuItem value="MN">MN</MenuItem>
                                                    <MenuItem value="MS">MS</MenuItem>
                                                    <MenuItem value="MO">MO</MenuItem>
                                                    <MenuItem value="MT">MT</MenuItem>
                                                    <MenuItem value="NE">NE</MenuItem>
                                                    <MenuItem value="NV">NV</MenuItem>
                                                    <MenuItem value="NH">NH</MenuItem>
                                                    <MenuItem value="NJ">NJ</MenuItem>
                                                    <MenuItem value="NM">NM</MenuItem>
                                                    <MenuItem value="NY">NY</MenuItem>
                                                    <MenuItem value="NC">NC</MenuItem>
                                                    <MenuItem value="ND">ND</MenuItem>
                                                    <MenuItem value="OH">OH</MenuItem>
                                                    <MenuItem value="OK">OK</MenuItem>
                                                    <MenuItem value="OR">OR</MenuItem>
                                                    <MenuItem value="PA">PA</MenuItem>
                                                    <MenuItem value="RI">RI</MenuItem>
                                                    <MenuItem value="SC">SC</MenuItem>
                                                    <MenuItem value="SD">SD</MenuItem>
                                                    <MenuItem value="TN">TN</MenuItem>
                                                    <MenuItem value="TX">TX</MenuItem>
                                                    <MenuItem value="UT">UT</MenuItem>
                                                    <MenuItem value="VT">VT</MenuItem>
                                                    <MenuItem value="VA">VA</MenuItem>
                                                    <MenuItem value="WA">WA</MenuItem>
                                                    <MenuItem value="WV">WV</MenuItem>
                                                    <MenuItem value="WI">WI</MenuItem>
                                                    <MenuItem value="WY">WY</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                            value={props.formStore.dissolveCompanyState.sos_number}
                                            onChange={(e) => props.formStore.setDissolveCompanyState('sos_number', e.target.value)}
                                            variant="outlined" placeholder="SOS Entity Number" label="SOS Entity Number" fullWidth />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Business Address</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <TextField value={props.formStore.dissolveCompanyState.address} onChange={(e) => props.formStore.setDissolveCompanyState('address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.dissolveCompanyState.city} onChange={(e) => props.formStore.setDissolveCompanyState('city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                            <TextField value={props.formStore.dissolveCompanyState.state} onChange={(e) => props.formStore.setDissolveCompanyState('state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                                            <TextField value={props.formStore.dissolveCompanyState.zipcode} onChange={(e) => props.formStore.setDissolveCompanyState('zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '3em' }}>
                                            <TextField value={props.formStore.dissolveCompanyState.county} onChange={(e) => props.formStore.setDissolveCompanyState('county', e.target.value)} variant="outlined" placeholder="County" label="County" fullWidth />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.formStore.dissolveCompanyState.diff_mailing_address}
                                                        onChange={(e) => props.formStore.setDissolveCompanyState('diff_mailing_address', e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Different Mailing Address?"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                {
                                    props.formStore.dissolveCompanyState.diff_mailing_address &&
                                    <>
                                        <div className={classes.einInfoContainer}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                                <span style={{ fontSize: '1.44em' }}>Business Mailing Address</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                                <TextField value={props.formStore.dissolveCompanyState.mailing_address} onChange={(e) => props.formStore.setDissolveCompanyState('mailing_address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                                                <div style={{ display: 'flex', gap: '0.45em' }}>
                                                    <TextField value={props.formStore.dissolveCompanyState.mailing_city} onChange={(e) => props.formStore.setDissolveCompanyState('mailing_city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                                    <TextField value={props.formStore.dissolveCompanyState.mailing_state} onChange={(e) => props.formStore.setDissolveCompanyState('mailing_state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                                                    <TextField value={props.formStore.dissolveCompanyState.mailing_zipcode} onChange={(e) => props.formStore.setDissolveCompanyState('mailing_zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />
                                    </>
                                }

                                

                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Registered Agent Information</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.dissolveCompanyState.agent_name} onChange={(e) => props.formStore.setDissolveCompanyState('agent_name', e.target.value)} variant="outlined" placeholder="Name of Registered Agent" label="Name of Registered Agent" fullWidth />
                                            <TextField value={props.formStore.dissolveCompanyState.agent_address} onChange={(e) => props.formStore.setDissolveCompanyState('agent_address', e.target.value)} variant="outlined" placeholder="Street Address" label="Street Address" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.dissolveCompanyState.agent_city} onChange={(e) => props.formStore.setDissolveCompanyState('agent_city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                            <TextField value={props.formStore.dissolveCompanyState.agent_county} onChange={(e) => props.formStore.setDissolveCompanyState('agent_county', e.target.value)} variant="outlined" placeholder="County" label="County" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>State of Formation</InputLabel>
                                                <Select
                                                    value={props.formStore.dissolveCompanyState.formation_state}
                                                    onChange={(e) => props.formStore.setDissolveCompanyState('formation_state', e.target.value)}
                                                    label="State of Formation"
                                                >   
                                                    <MenuItem value="AL">AL</MenuItem>
                                                    <MenuItem value="AK">AK</MenuItem>
                                                    <MenuItem value="AZ">AZ</MenuItem>
                                                    <MenuItem value="AR">AR</MenuItem>
                                                    <MenuItem value="CA">CA</MenuItem>
                                                    <MenuItem value="CO">CO</MenuItem>
                                                    <MenuItem value="CT">CT</MenuItem>
                                                    <MenuItem value="DE">DE</MenuItem>
                                                    <MenuItem value="FL">FL</MenuItem>
                                                    <MenuItem value="GA">GA</MenuItem>
                                                    <MenuItem value="HI">HI</MenuItem>
                                                    <MenuItem value="ID">ID</MenuItem>
                                                    <MenuItem value="IL">IL</MenuItem>
                                                    <MenuItem value="IN">IN</MenuItem>
                                                    <MenuItem value="IA">IA</MenuItem>
                                                    <MenuItem value="KS">KS</MenuItem>
                                                    <MenuItem value="KY">KY</MenuItem>
                                                    <MenuItem value="LA">LA</MenuItem>
                                                    <MenuItem value="ME">ME</MenuItem>
                                                    <MenuItem value="MD">MD</MenuItem>
                                                    <MenuItem value="MA">MA</MenuItem>
                                                    <MenuItem value="MI">MI</MenuItem>
                                                    <MenuItem value="MN">MN</MenuItem>
                                                    <MenuItem value="MS">MS</MenuItem>
                                                    <MenuItem value="MO">MO</MenuItem>
                                                    <MenuItem value="MT">MT</MenuItem>
                                                    <MenuItem value="NE">NE</MenuItem>
                                                    <MenuItem value="NV">NV</MenuItem>
                                                    <MenuItem value="NH">NH</MenuItem>
                                                    <MenuItem value="NJ">NJ</MenuItem>
                                                    <MenuItem value="NM">NM</MenuItem>
                                                    <MenuItem value="NY">NY</MenuItem>
                                                    <MenuItem value="NC">NC</MenuItem>
                                                    <MenuItem value="ND">ND</MenuItem>
                                                    <MenuItem value="OH">OH</MenuItem>
                                                    <MenuItem value="OK">OK</MenuItem>
                                                    <MenuItem value="OR">OR</MenuItem>
                                                    <MenuItem value="PA">PA</MenuItem>
                                                    <MenuItem value="RI">RI</MenuItem>
                                                    <MenuItem value="SC">SC</MenuItem>
                                                    <MenuItem value="SD">SD</MenuItem>
                                                    <MenuItem value="TN">TN</MenuItem>
                                                    <MenuItem value="TX">TX</MenuItem>
                                                    <MenuItem value="UT">UT</MenuItem>
                                                    <MenuItem value="VT">VT</MenuItem>
                                                    <MenuItem value="VA">VA</MenuItem>
                                                    <MenuItem value="WA">WA</MenuItem>
                                                    <MenuItem value="WV">WV</MenuItem>
                                                    <MenuItem value="WI">WI</MenuItem>
                                                    <MenuItem value="WY">WY</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField value={props.formStore.dissolveCompanyState.agent_zipcode} onChange={(e) => props.formStore.setDissolveCompanyState('agent_zipcode', e.target.value)} variant="outlined" placeholder="Zip Code" label="Zip Code" fullWidth />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Business Managers</span>
                                        <Button onClick={() => props.formStore.addDissolveCompanyManager()} variant="outlined" color="primary" style={{ width: 'fit-content' }}>Add Manager</Button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        {
                                            props.formStore.dissolveCompanyState.managers.map((manager, index) => {
                                                return (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                                            <TextField value={manager.full_name} onChange={(e) => props.formStore.changeDissolveCompanyManager(index, 'full_name', e.target.value)} variant="outlined" placeholder="Full Name" label="Full Name" fullWidth />
                                                            <TextField value={manager.address} onChange={(e) => props.formStore.changeDissolveCompanyManager(index, 'address', e.target.value)} variant="outlined" placeholder="Street Address" label="Street Address" fullWidth />
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                                            <TextField value={manager.city} onChange={(e) => props.formStore.changeDissolveCompanyManager(index, 'city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <InputLabel>State</InputLabel>
                                                                <Select
                                                                    value={manager.state}
                                                                    onChange={(e) => props.formStore.changeDissolveCompanyManager(index, 'state', e.target.value)}
                                                                    label="State"
                                                                >   
                                                                    <MenuItem value="AL">AL</MenuItem>
                                                                    <MenuItem value="AK">AK</MenuItem>
                                                                    <MenuItem value="AZ">AZ</MenuItem>
                                                                    <MenuItem value="AR">AR</MenuItem>
                                                                    <MenuItem value="CA">CA</MenuItem>
                                                                    <MenuItem value="CO">CO</MenuItem>
                                                                    <MenuItem value="CT">CT</MenuItem>
                                                                    <MenuItem value="DE">DE</MenuItem>
                                                                    <MenuItem value="FL">FL</MenuItem>
                                                                    <MenuItem value="GA">GA</MenuItem>
                                                                    <MenuItem value="HI">HI</MenuItem>
                                                                    <MenuItem value="ID">ID</MenuItem>
                                                                    <MenuItem value="IL">IL</MenuItem>
                                                                    <MenuItem value="IN">IN</MenuItem>
                                                                    <MenuItem value="IA">IA</MenuItem>
                                                                    <MenuItem value="KS">KS</MenuItem>
                                                                    <MenuItem value="KY">KY</MenuItem>
                                                                    <MenuItem value="LA">LA</MenuItem>
                                                                    <MenuItem value="ME">ME</MenuItem>
                                                                    <MenuItem value="MD">MD</MenuItem>
                                                                    <MenuItem value="MA">MA</MenuItem>
                                                                    <MenuItem value="MI">MI</MenuItem>
                                                                    <MenuItem value="MN">MN</MenuItem>
                                                                    <MenuItem value="MS">MS</MenuItem>
                                                                    <MenuItem value="MO">MO</MenuItem>
                                                                    <MenuItem value="MT">MT</MenuItem>
                                                                    <MenuItem value="NE">NE</MenuItem>
                                                                    <MenuItem value="NV">NV</MenuItem>
                                                                    <MenuItem value="NH">NH</MenuItem>
                                                                    <MenuItem value="NJ">NJ</MenuItem>
                                                                    <MenuItem value="NM">NM</MenuItem>
                                                                    <MenuItem value="NY">NY</MenuItem>
                                                                    <MenuItem value="NC">NC</MenuItem>
                                                                    <MenuItem value="ND">ND</MenuItem>
                                                                    <MenuItem value="OH">OH</MenuItem>
                                                                    <MenuItem value="OK">OK</MenuItem>
                                                                    <MenuItem value="OR">OR</MenuItem>
                                                                    <MenuItem value="PA">PA</MenuItem>
                                                                    <MenuItem value="RI">RI</MenuItem>
                                                                    <MenuItem value="SC">SC</MenuItem>
                                                                    <MenuItem value="SD">SD</MenuItem>
                                                                    <MenuItem value="TN">TN</MenuItem>
                                                                    <MenuItem value="TX">TX</MenuItem>
                                                                    <MenuItem value="UT">UT</MenuItem>
                                                                    <MenuItem value="VT">VT</MenuItem>
                                                                    <MenuItem value="VA">VA</MenuItem>
                                                                    <MenuItem value="WA">WA</MenuItem>
                                                                    <MenuItem value="WV">WV</MenuItem>
                                                                    <MenuItem value="WI">WI</MenuItem>
                                                                    <MenuItem value="WY">WY</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <TextField value={manager.zipcode} onChange={(e) => props.formStore.changeDissolveCompanyManager(index, 'zipcode', e.target.value)} variant="outlined" placeholder="Zip Code" label="Zip Code" fullWidth />
                                                        <div style={{ borderBottom: '1px dotted #e6e6e6', margin: '1.2em 0' }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>{props.formStore.dissolveCompanyState.entity_type} Members</span>
                                        <span>Members that can approve the dissolution</span>
                                        <Button onClick={() => props.formStore.addDissolveCompanyMember()} variant="outlined" color="primary" style={{ width: 'fit-content' }}>Add Member</Button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        {
                                            props.formStore.dissolveCompanyState.members.map((member, index) => {
                                                return (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                                            <TextField value={member.full_name} onChange={(e) => props.formStore.changeDissolveCompanyMember(index, 'full_name', e.target.value)} variant="outlined" placeholder="Full Name" label="Full Name" fullWidth />
                                                            <TextField value={member.address} onChange={(e) => props.formStore.changeDissolveCompanyMember(index, 'address', e.target.value)} variant="outlined" placeholder="Street Address" label="Street Address" fullWidth />
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                                            <TextField value={member.city} onChange={(e) => props.formStore.changeDissolveCompanyMember(index, 'city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                                            <FormControl variant="outlined" className={classes.formControl}>
                                                                <InputLabel>State</InputLabel>
                                                                <Select
                                                                    value={member.state}
                                                                    onChange={(e) => props.formStore.changeDissolveCompanyMember(index, 'state', e.target.value)}
                                                                    label="State"
                                                                >   
                                                                    <MenuItem value="AL">AL</MenuItem>
                                                                    <MenuItem value="AK">AK</MenuItem>
                                                                    <MenuItem value="AZ">AZ</MenuItem>
                                                                    <MenuItem value="AR">AR</MenuItem>
                                                                    <MenuItem value="CA">CA</MenuItem>
                                                                    <MenuItem value="CO">CO</MenuItem>
                                                                    <MenuItem value="CT">CT</MenuItem>
                                                                    <MenuItem value="DE">DE</MenuItem>
                                                                    <MenuItem value="FL">FL</MenuItem>
                                                                    <MenuItem value="GA">GA</MenuItem>
                                                                    <MenuItem value="HI">HI</MenuItem>
                                                                    <MenuItem value="ID">ID</MenuItem>
                                                                    <MenuItem value="IL">IL</MenuItem>
                                                                    <MenuItem value="IN">IN</MenuItem>
                                                                    <MenuItem value="IA">IA</MenuItem>
                                                                    <MenuItem value="KS">KS</MenuItem>
                                                                    <MenuItem value="KY">KY</MenuItem>
                                                                    <MenuItem value="LA">LA</MenuItem>
                                                                    <MenuItem value="ME">ME</MenuItem>
                                                                    <MenuItem value="MD">MD</MenuItem>
                                                                    <MenuItem value="MA">MA</MenuItem>
                                                                    <MenuItem value="MI">MI</MenuItem>
                                                                    <MenuItem value="MN">MN</MenuItem>
                                                                    <MenuItem value="MS">MS</MenuItem>
                                                                    <MenuItem value="MO">MO</MenuItem>
                                                                    <MenuItem value="MT">MT</MenuItem>
                                                                    <MenuItem value="NE">NE</MenuItem>
                                                                    <MenuItem value="NV">NV</MenuItem>
                                                                    <MenuItem value="NH">NH</MenuItem>
                                                                    <MenuItem value="NJ">NJ</MenuItem>
                                                                    <MenuItem value="NM">NM</MenuItem>
                                                                    <MenuItem value="NY">NY</MenuItem>
                                                                    <MenuItem value="NC">NC</MenuItem>
                                                                    <MenuItem value="ND">ND</MenuItem>
                                                                    <MenuItem value="OH">OH</MenuItem>
                                                                    <MenuItem value="OK">OK</MenuItem>
                                                                    <MenuItem value="OR">OR</MenuItem>
                                                                    <MenuItem value="PA">PA</MenuItem>
                                                                    <MenuItem value="RI">RI</MenuItem>
                                                                    <MenuItem value="SC">SC</MenuItem>
                                                                    <MenuItem value="SD">SD</MenuItem>
                                                                    <MenuItem value="TN">TN</MenuItem>
                                                                    <MenuItem value="TX">TX</MenuItem>
                                                                    <MenuItem value="UT">UT</MenuItem>
                                                                    <MenuItem value="VT">VT</MenuItem>
                                                                    <MenuItem value="VA">VA</MenuItem>
                                                                    <MenuItem value="WA">WA</MenuItem>
                                                                    <MenuItem value="WV">WV</MenuItem>
                                                                    <MenuItem value="WI">WI</MenuItem>
                                                                    <MenuItem value="WY">WY</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <TextField value={member.zipcode} onChange={(e) => props.formStore.changeDissolveCompanyMember(index, 'zipcode', e.target.value)} variant="outlined" placeholder="Zip Code" label="Zip Code" fullWidth />
                                                        <div style={{ borderBottom: '1px dotted #e6e6e6', margin: '1.2em 0' }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{ color: '#fff', height: '51px' }}
                                    color="primary"
                                    onClick={submitApplication}
                                    disabled={loadingState}>
                                    Submit Application

                                    {loadingState && <CircularProgress size={13} className={classes.buttonProgress} />}
                                </Button>
                                <span style={{ display: 'flex', justifyContent: 'center' }}>By clicking on "Submit Application" you are agreeing to our Terms of Service and Privacy Policy.</span>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </DashboardLayout>
    );
}))

import React from 'react';
import { Routes, Route } from 'react-router';

import Layout from './components/Layout';
import Login from './pages/Login';
import Logout from './pages/Logout';
import InvoicePayment from './pages/InvoicePayment';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Refund from './pages/Refund';
import Cart from './pages/Cart';
import Products from './pages/Products';
import Product from './pages/Product';
import Checkout from './pages/Checkout';
import Account from './pages/Account';
import Reports from './pages/Reports';
import Labor from './pages/Labor';
import Certificate from './pages/Certificate';
import Orders from './pages/Orders';
import EIN from './pages/EIN';
import FormCompany from './pages/FormCompany';
import DissolveCompany from './pages/DissolveCompany';
import FormCreation from './pages/FormCreation';
import Subscribe from './pages/Subscribe';

// updated packages.json
const App = () => (
    <Layout>
        <Routes>
            <Route path='/' exact element={<About />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/pay/:attributes' element={<InvoicePayment />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/refund-policy' element={<Refund />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/products' element={<Products />} />
            <Route path='/product/:id' element={<Product />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/account' element={<Account />} />
            <Route path='/reports' element={<Reports />} />
            <Route path='/labor' element={<Labor />} />
            <Route path='/certificate' element={<Certificate />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/ein/:token' element={<EIN />} />
            <Route path='/form-company' element={<FormCompany />} />
            <Route path='/dissolve-company' element={<DissolveCompany />} />
            <Route path='/form' element={<FormCreation />} />
            <Route path='/subscribe/:company/:email/:descriptor/:value' element={<Subscribe />} />
        </Routes>
    </Layout>
);

export default App;

import React, { useEffect } from 'react';

import useStyles from '../styles/styles';

export default () => {
    const classes = useStyles();

    let user_cookie = document.cookie.split(';')[0].split('mxAkskd1dmA=')[1];
    let token_cookie = document.cookie.split(';')[1].split('lSj2aoXmn2=')[1];

    useEffect(() => {
        document.cookie = "bG9naW5jb29raWU=aXN0cnVl; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `lSj2aoXmn2=${token_cookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        
        window.location.href = '/';
    }, []);

    return (
        <div className={classes.logOff}>
            <p>Logging off...</p>
        </div>
    )
}

import React from 'react';
import { useParams } from 'react-router-dom';

import { BsCheckAll } from 'react-icons/bs'

import HostedForm from '../components/AuthorizeHostedForm';

import Toast from '../components/Toast'
import { toast } from 'react-toastify'

const authData = {
    apiLoginID: process.env.API_LOGIN_ID,
    clientKey: process.env.API_CLIENT_KEY,
}
const payButtonStyle = {
    color: 'rgb(255, 255, 255)',
    width: '100%',
    padding: '6px 16px',
    border: '0px',
    backgroundColor: '#1E40AF',
    cursor: 'pointer',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    fontWeight: '500',
    borderRadius: '4.5px',
}

const AuthorizeNetHostedForm = ({
    params,
    showSuccessModal,
    setPaid,
    showErrorModal,
}) => {
    const handleSubmit = (hostedFormResponse) => {
        if (hostedFormResponse.messages.resultCode === 'Ok') {
            showSuccessModal('Verifying payment please wait')
            fetch(`${process.env.API_URL}/auto-subscribe/?company=${params.company}&email=${params.email}&dataDescriptor=${hostedFormResponse.opaqueData.dataDescriptor}&dataValue=${hostedFormResponse.opaqueData.dataValue}&firstName=${hostedFormResponse.customerInformation.firstName}&lastName=${hostedFormResponse.customerInformation.lastName}`).then(res => {
                if (res.status === 200) {
                    setPaid(true)
                    showSuccessModal(`Subscribed Successfully`)
                } else {
                    showErrorModal('Subscribing Unsuccessful, please try again')
                }
            }).catch(err => {
                console.log(err);
                showErrorModal('Subscribing Unsuccessful, please try again')
            });
        } else {
            showErrorModal('Subscribing Unsuccessful, please try again')

        }
    };

    return (
        <div className="row">
            <div className="col-1" />
            <div className="col-5 d-flex align-items-center justify-content-center">
                <HostedForm
                    environment={authData.apiLoginID === '3Vrv7kybU2F' ? 'SANDBOX' : 'PRODUCTION'}
                    authData={authData}
                    onSubmit={handleSubmit}
                    buttonStyle={payButtonStyle}
                    buttonText="Subscribe 1-year $0"
                    formButtonText="Subscribe"
                    formHeaderText="Subscribe"
                    billingAddressOptions={{ show: true, required: true }}
                    paymentOptions={{ showCreditCard: true, showBankAccount: false }}
                />
            </div>
        </div>
    );
}

export default function Subscribe() {
    let params = useParams();

    const [paid, setPaid] = React.useState(false)

    const showSuccessModal = (msg) => {
        toast(
            <Toast title={"Success"} msg={msg} />
        )
    }

    const showErrorModal = (msg) => {
        toast(
            <Toast title={"Error occurred"} msg={msg} />
        )
    }

    return (
        <div className="h-full w-full">
            <div className="mx-auto my-9 p-3 max-w-md border border-gray-300 rounded-md bg-gray-100">
                {
                    paid ?
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2 items-center">
                                <div className="border border-green-600 rounded-full p-1">
                                    <BsCheckAll className="text-green-600" />
                                </div>
                                <span>
                                    Subscribed Successfully
                                </span>
                            </div>
                            <div>
                                <span className="text-sm text-gray-600">
                                    You have successfully subscribed {params.company}!
                                </span>
                            </div>
                            <div>
                                <span className="text-sm text-gray-600">
                                    Dashboard credentials will be sent to your email. If you have any questions
                                    please don't hesitate to contact us at support@laborcomplianceservice.com.
                                </span>
                            </div>
                        </div>
                        :
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-2 items-center">
                                <div className="border border-green-600 rounded-full p-1">
                                    <BsCheckAll className="text-green-600" />
                                </div>
                                <span>
                                    Subscribe as {params.company}
                                </span>
                            </div>
                            <AuthorizeNetHostedForm
                                params={params}
                                showSuccessModal={showSuccessModal}
                                setPaid={setPaid}
                                showErrorModal={showErrorModal}
                            />
                        </div>
                }
            </div>
        </div>
    );
}

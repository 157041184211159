import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Toast from '../components/Toast'
import { toast } from 'react-toastify'

const payButtonStyle = {
    color: 'rgb(255, 255, 255)',
    width: '100%',
    padding: '6px 16px',
    border: '0px',
    backgroundColor: '#1E40AF',
    cursor: 'pointer',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    fontWeight: '500',
    borderRadius: '4.5px',
}

const Form = () => {
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [data, setData] = React.useState({
        company: '',
        email: '',
        state: '',
    })
    const submit = () => {
        if (
            data.company === '' ||
            data.email === '' ||
            data.state === ''
        ) {
            toast(
                <Toast title={"Success"} msg="Please fill out the form" />
            )
        } else {
            setLoading(true)
            fetch(`${process.env.API_URL}/missing-client/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.status === 200) {
                    setSuccess(true)
                }
            }).catch(err => console.log(err))
        }
    }

    return (
        <React.Fragment>
            <div className="max-w-md m-auto p-6 rounded-md bg-gray-100 my-3 flex flex-col gap-3">
                <span className="text-xl">
                    Please fill form below
                </span>
                <div className="flex flex-col gap-1">
                    <span>Company</span>
                    <input
                        type="text"
                        className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                        placeholder="Company"
                        value={data.company}
                        onChange={(e) => setData({ ...data, company: e.target.value })}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <span>Email</span>
                    <input
                        type="text"
                        className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                        placeholder="Email"
                        value={data.email}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <span>State</span>
                    <input
                        type="text"
                        className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                        placeholder="State"
                        value={data.state}
                        onChange={(e) => setData({ ...data, state: e.target.value })}
                    />
                </div>
                {
                    success ?
                    <p className="text-green-700">
                        Thank you for submitting form. We will contact you shortly about payment link.
                    </p>
                    :
                    <button
                    onClick={submit}
                    style={payButtonStyle}
                    disabled={loading}
                >
                    {
                        loading ?
                            <span className="flex items-center justify-center">
                                Please wait
                                <CircularProgress size={12} className="ml-1.5 !text-white" />
                            </span>
                            :
                            'Continue'
                    }
                </button>
                }
            </div>
        </React.Fragment>
    );
}

export default Form;

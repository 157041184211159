import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LockIcon from '@material-ui/icons/Lock';

import useStyles from '../styles/styles';

export default (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.processDiv}>
                <FingerprintIcon className={classes.fingerPrintIcon} color="primary" />

                <p style={{ textAlign: 'center', color: 'rgb(168, 168, 168)' }}>Sign in with credentials</p>

                <FormControl className={classes.formControl}>
                    <TextField
                    label="Email"
                    name="email"
                    className={classes.textField}
                    value={props.username}
                    onChange={props.setForm} 
                    margin="normal"
                    variant="filled"
                    error={props.usernameError}
                    helperText={props.usernameErrorText}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailIcon />
                          </InputAdornment>
                        ),
                    }}
                    />
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField
                    label="Password"
                    name="password"
                    className={classes.textField}
                    value={props.password}
                    onChange={props.setForm} 
                    margin="normal"
                    variant="filled"
                    type="password"
                    error={props.passwordError}
                    helperText={props.passwordErrorText}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                    }}
                    />
                </FormControl>

                <button className={classes.loginButton} onClick={props.submitForm}  disabled={props.loadingState}>
                    Sign In
                    
                    {props.loadingState && <CircularProgress size={13} className={classes.buttonProgress} />}
                </button>
            </div>
        </React.Fragment>
  );
}

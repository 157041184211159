import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DesktopMenu from '../components/DesktopMenu';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

const DashboardLayout = (props) => {
    if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    } else {
        return (
            <div />
        )
    }
};

export default inject('alertStore', 'formStore')(observer((props) => {
    const navigate = useNavigate();

    const [loadingState, setLoadingState] = React.useState(true);
    const [formCompanyApplicationsState, setFormCompanyApplicationsState] = React.useState([]);
    const [statusState, setStatusState] = React.useState(0);

    React.useEffect(() => {
        if (!/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            navigate('/login');
        } else {
            fetch(`${process.env.API_URL}/notifications/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                }
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail !== undefined) {
                    for (const notification of res.detail) {
                        props.alertStore.pushDashboardAlert({
                            state: notification.state,
                            message: notification.notification
                        });
                    }

                    setTimeout(() => {
                        props.alertStore.clearDashboardAlerts();
                    }, 30000);
                }
            }).catch(err => {
                console.log(err);
            });

            fetch(`${process.env.API_URL}/form-company-control/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                },
                body: JSON.stringify({ 'view-type': 'all' })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                setFormCompanyApplicationsState(res.detail);
                setLoadingState(false);
                window.scrollTo(0, 0);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    const classes = useStyles();

    const viewApplication = (el) => {
        for (const key of Object.keys(el)) {
            props.formStore.setFormCompanyState(key, el[key]);
        }

        setStatusState(1);
    };

    const submitApplication = () => {
        if (props.formStore.formCompanyState.name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.first_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill first name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }
        
        else if (props.formStore.formCompanyState.last_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill last name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.type === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill type');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.ssn !== props.formStore.formCompanyState.verify_ssn) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'SSN is incorrect');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.category === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill category');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.address === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.city === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.state === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.zipcode === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.county === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill county');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.mailing_address === '' && props.formStore.formCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.mailing_city === '' && props.formStore.formCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.mailing_state === '' && props.formStore.formCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.mailing_zipcode === '' && props.formStore.formCompanyState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.description === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill description');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.formCompanyState.only_owner === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill only owner');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else {
            props.formStore.setFormCompanyState('application_name', `${props.formStore.formCompanyState.name.toUpperCase()}-${Math.floor(Date.now() / 1000)}`);
            setLoadingState(true);

            fetch(`${process.env.API_URL}/form-company-control/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                },
                body: JSON.stringify({ 'view-type': 'new', ...toJS(props.formStore.formCompanyState) })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail === 'Success') {
                    window.location.reload();
                } else {
                    window.scrollTo(0, 0);
                    props.alertStore.setAlertState('show', true);
                    props.alertStore.setAlertState('state', 'error');
                    props.alertStore.setAlertState('message', res.detail.invalid);
        
                    setTimeout(() => {
                        props.alertStore.setAlertState('show', false);
                        props.alertStore.setAlertState('message', '');
                    }, 4500);
                }
                setLoadingState(false);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    return (
        <DashboardLayout>

            <div className={classes.wrapper}>
                <div className={classes.homeGrid}>

                    <div className={classes.homeMenu}>

                        <DesktopMenu fontColor="black" />

                    </div>

                    <div className={classes.homeLayoutGrid} style={{ padding: '15px' }}>

                        {
                            statusState === 0 &&
                            <>
                                <div className={classes.roundDiv}>
                                    <div className={classes.einEntityType}>
                                        <span style={{ fontSize: '1.5em' }}>Get your LLC started in 5 minutes</span>
                                        <Button onClick={() => setStatusState(1)} style={{ color: '#fff' }} variant="contained" color="primary">Get Started</Button>
                                    </div>
                                </div>

                                <div className={classes.roundDiv}>
                                        <span style={{ fontSize: '1.5em' }}>
                                            Submitted Applications
                                        </span>
                                        <div className={classes.roundDiv} style={{ display: 'flex', gap: '0.45em', flexDirection: 'column', marginTop: '1.2em' }}>
                                            {
                                                loadingState ?
                                                <LinearProgress />
                                                :
                                                formCompanyApplicationsState.length === 0 ?
                                                <span>None found</span>
                                                :
                                                formCompanyApplicationsState.map(el => {
                                                    return (
                                                        <div key={el.id} onClick={() => viewApplication(el)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{el.application_name}</span>
                                                            <span>{el.status}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                </div>
                            </>
                        }

                        {
                            statusState === 1 &&
                            <div className={classes.roundDiv}>

                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Personal Information</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.formCompanyState.first_name} onChange={(e)  => props.formStore.setFormCompanyState('first_name', e.target.value)} variant="outlined" placeholder="First name" label="First name" fullWidth />
                                            <TextField value={props.formStore.formCompanyState.middle_name} onChange={(e)  => props.formStore.setFormCompanyState('middle_name', e.target.value)} variant="outlined" placeholder="Middle name" label="Middle name (Optional)" fullWidth />
                                            <TextField value={props.formStore.formCompanyState.last_name} onChange={(e)  => props.formStore.setFormCompanyState('last_name', e.target.value)} variant="outlined" placeholder="Last name" label="Last name" fullWidth />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />

                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Business Information</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>Entity Type</InputLabel>
                                                <Select
                                                    value={props.formStore.formCompanyState.type}
                                                    onChange={(e) => props.formStore.setFormCompanyState('type', e.target.value)}
                                                    label="Entity Type"
                                                >
                                                    <MenuItem value="LLC">LLC</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField value={props.formStore.formCompanyState.name} onChange={(e) => props.formStore.setFormCompanyState('name', e.target.value)} variant="outlined" placeholder="LLC Name" label="LLC Name" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>Business Category</InputLabel>
                                                <Select
                                                    value={props.formStore.formCompanyState.category}
                                                    onChange={(e) => props.formStore.setFormCompanyState('category', e.target.value)}
                                                    label="Business Category"
                                                >
                                                    <MenuItem value="Automotive">Automotive</MenuItem>
                                                    <MenuItem value="Beauty">Beauty</MenuItem>
                                                    <MenuItem value="Cleaning Service">Cleaning Service</MenuItem>
                                                    <MenuItem value="Child Care">Child Care</MenuItem>
                                                    <MenuItem value="Consulting">Consulting</MenuItem>
                                                    <MenuItem value="Construction">Construction</MenuItem>
                                                    <MenuItem value="Education">Education</MenuItem>
                                                    <MenuItem value="Entertainment">Entertainment</MenuItem>
                                                    <MenuItem value="Food and Service">Food and Service</MenuItem>
                                                    <MenuItem value="Gym and Fitness">Gym and Fitness</MenuItem>
                                                    <MenuItem value="Investments">Investments</MenuItem>
                                                    <MenuItem value="Photography">Photography</MenuItem>
                                                    <MenuItem value="Real Estate">Real Estate</MenuItem>
                                                    <MenuItem value="Retail">Retail</MenuItem>
                                                    <MenuItem value="Transporation">Transporation</MenuItem>
                                                    <MenuItem value="Technology">Technology</MenuItem>
                                                    <MenuItem value="Other">Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                            value={props.formStore.formCompanyState.description}
                                            onChange={(e) => props.formStore.setFormCompanyState('description', e.target.value)}
                                            variant="outlined" placeholder="What does your business do?" label="What does your business do?" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>Are you the only owner and officer of this business?</InputLabel>
                                                <Select
                                                    value={props.formStore.formCompanyState.only_owner}
                                                    onChange={(e) => props.formStore.setFormCompanyState('only_owner', e.target.value)}
                                                    label="Are you the only owner and officer of this business?"
                                                >
                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                    <MenuItem value="No">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <span>Your LLC expert will reach out to you after checkout to collect information about any other owners.</span>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Business Address</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <TextField value={props.formStore.formCompanyState.address} onChange={(e) => props.formStore.setFormCompanyState('address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.formCompanyState.city} onChange={(e) => props.formStore.setFormCompanyState('city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                            <TextField value={props.formStore.formCompanyState.state} onChange={(e) => props.formStore.setFormCompanyState('state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                                            <TextField value={props.formStore.formCompanyState.zipcode} onChange={(e) => props.formStore.setFormCompanyState('zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                                        </div>
                                        <div style={{ display: 'flex', gap: '3em' }}>
                                            <TextField value={props.formStore.formCompanyState.county} onChange={(e) => props.formStore.setFormCompanyState('county', e.target.value)} variant="outlined" placeholder="County" label="County" fullWidth />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.formStore.formCompanyState.diff_mailing_address}
                                                        onChange={(e) => props.formStore.setFormCompanyState('diff_mailing_address', e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Different Mailing Address?"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


                                {
                                    props.formStore.formCompanyState.diff_mailing_address &&
                                    <>
                                        <div className={classes.einInfoContainer}>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                                <span style={{ fontSize: '1.44em' }}>Business Mailing Address</span>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                                <TextField value={props.formStore.formCompanyState.mailing_address} onChange={(e) => props.formStore.setFormCompanyState('mailing_address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                                                <div style={{ display: 'flex', gap: '0.45em' }}>
                                                    <TextField value={props.formStore.formCompanyState.mailing_city} onChange={(e) => props.formStore.setFormCompanyState('mailing_city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                                    <TextField value={props.formStore.formCompanyState.mailing_state} onChange={(e) => props.formStore.setFormCompanyState('mailing_state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                                                    <TextField value={props.formStore.formCompanyState.mailing_zipcode} onChange={(e) => props.formStore.setFormCompanyState('mailing_zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />
                                    </>
                                }

                                

                                <div className={classes.einInfoContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                                        <span style={{ fontSize: '1.44em' }}>Tax Information</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                                        <div style={{ display: 'flex', gap: '0.45em' }}>
                                            <TextField value={props.formStore.formCompanyState.ssn} onChange={(e) => props.formStore.setFormCompanyState('ssn', e.target.value)} variant="outlined" placeholder="Social Security Number" label="Social Security Number" fullWidth />
                                            <TextField value={props.formStore.formCompanyState.verify_ssn} onChange={(e) => props.formStore.setFormCompanyState('verify_ssn', e.target.value)} variant="outlined" placeholder="Verify Social Security Number" label="Verify Social Security Number" fullWidth />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />

                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{ color: '#fff', height: '51px' }}
                                    color="primary"
                                    onClick={submitApplication}
                                    disabled={loadingState}>
                                    Submit Application

                                    {loadingState && <CircularProgress size={13} className={classes.buttonProgress} />}
                                </Button>
                                <span style={{ display: 'flex', justifyContent: 'center' }}>By clicking on "Submit Application" you are agreeing to our Terms of Service and Privacy Policy.</span>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </DashboardLayout>
    );
}))

import { observable, action } from 'mobx';

export class UiStateStore {
    uiState: any = observable({
        drawerState: false,
        loadingState: false,
        loadingIcon: null,
        cart: [],
        navMenuTitle: ''
    });

    checkoutState: any = observable({
        email: '',
        first_name: '',
        last_name: '',
        company: '',
        address: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        phone: ''
    });

    clientDataState: any = observable({
        email: '',
        name: '',
        phone: '',
        city: '',
        zip: '',
        address: '',
        state: '',
        ein: false,
        certificateStanding: false,
        totalPrice: '',
        payment_box: 'default',
    });

    setClientDataKeyValue = action((key: any, value: any) => {
        this.clientDataState[key] = value;
    });

    setDrawerState = action((value: any) => {
        this.uiState.drawerState = value;
    });

    setLoadingState = action((value: any) => {
        this.uiState.loadingState = value;
    });

    setLoadingIcon = action((value: any) => {
        this.uiState.loadingIcon = value;
    });

    addProduct = action((value: object) => {
        this.uiState.cart.push(value);
    });

    removeProduct = action((ind: number) => {
        this.uiState.cart.splice(ind, 1);
    });

    totalCheckout = action(() => {
        let value = 0;
        for (const item of this.uiState.cart) {
            value += item.price;
        }

        return value;
    });

    changeEmail = action((e: any) => {
        this.checkoutState.email = e.target.value;
    });

    changeFirstName = action((e: any) => {
        this.checkoutState.first_name = e.target.value;
    });

    changeLastName = action((e: any) => {
        this.checkoutState.last_name = e.target.value;
    });

    changeCompany = action((e: any) => {
        this.checkoutState.company = e.target.value;
    });

    changeAddress = action((e: any) => {
        this.checkoutState.address = e.target.value;
    });

    changeCity = action((e: any) => {
        this.checkoutState.city = e.target.value;
    });

    changeCountry = action((e: any) => {
        this.checkoutState.country = e.target.value;
    });

    changeState = action((e: any) => {
        this.checkoutState.state = e.target.value;
    });

    changePhone = action((e: any) => {
        this.checkoutState.phone = e.target.value;
    });

    changeZip = action((e: any) => {
        this.checkoutState.zip = e.target.value;
    });

    changeNavMenuTitle = action((str: any) => {
        this.uiState.navMenuTitle = str;
    });
}

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloudIcon from '@material-ui/icons/Cloud';
import LayersIcon from '@material-ui/icons/Layers';
import SettingsIcon from '@material-ui/icons/Settings';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import PeopleIcon from '@material-ui/icons/People';
import MemoryIcon from '@material-ui/icons/Memory';
import TimelineIcon from '@material-ui/icons/Timeline';

//import Header from '../components/header';
//import Footer from '../components/footer';

import Dashboard from '../img/dashboard.png';

import useStyles from '../styles/styles';

import Skeleton from 'react-loading-skeleton';

const Home = () => {
    const [cookieConsent, setCookieConsent] = useState(true);
    const [productsState, setProductsState] = React.useState([]);

    React.useEffect(() => {
        fetch(`${process.env.API_URL}/products/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },  
            body: JSON.stringify({ type: 'all', page: 1 })
        }).then(res => res.json()).then(res => {
            if (res.detail.data !== undefined) {
                setProductsState(res.detail.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const handleCloseCookie = () => {
        setCookieConsent(false);
    };

    const classes = useStyles();

    return (
        <div>
            <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={cookieConsent}
                    message={
                        <span>
                            We use cookies to improve your experience and for <br /> marketing.
                            Learn more in our cookie policy.
                            <Button color="secondary" size="small" href="/privacy-policy">
                                Manage Cookies
                            </Button>
                        </span>
                    }
            />
            <div className={classes.mainContent}>

                <div className={classes.landingPageMainContainer}>
                    <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                        <div className={classes.landingPageMainTextContainer}>
                            <h1 className={classes.landingPageMainText}>
                                A worry-free solution for Labor Law Posters
                            </h1>
                            <div className={classes.landingPageMainSubtextContainer}>
                                <span className={classes.landingPageMainSubtext}>
                                    At State Filing Solutions we've got you covered.
                                </span>
                                <span className={classes.landingPageMainSubtext}>
                                    2022 Posters now available with FREE SHIPPING.
                                </span>
                            </div>
                            <NavLink to="/products" className={classes.linkClass}>
                                <Button variant="contained"
                                    color="primary" disableElevation
                                    classes={{ root: classes.headerStartNowButton }}>
                                    Get Started
                                </Button>
                            </NavLink>
                        </div>


                        <div className={classes.productsGridContainer}>
                    {
                        productsState.length > 0 ?
                        productsState.map((el, ind) => {
                            return (
                                <NavLink key={ind} to={`/product/${el.id}`} exact activeClassName={classes.linkNoDecoration} className={classes.linkNoDecoration}>
                                    <div style={{ cursor: 'pointer', height: '330px', border: '1px solid #e6e6e6', borderRadius: '0.75em', padding: '1.5em' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={el.img} width="81%" />
                                        </div>
                                        <div style={{ padding: '1em 0' }}>
                                            <div>
                                                <span style={{ fontSize: '1.23em' }}>{el.name}</span>
                                            </div>
                                            <div style={{ paddingTop: '0.45em' }}>
                                                {
                                                    el.discount_price !== 0 &&
                                                    <span style={{ color: 'gray', fontSize: '1.17em', textDecoration: 'line-through', textDecorationThickness: '3px', marginRight: '0.3em' }}>${el.discount_price}</span>
                                                }
                                                <span style={{ color: '#19a1ff', fontSize: '1.17em' }}>${el.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            )
                        })
                        :
                        [...Array(8)].map((el, ind) => {
                            return <div key={ind}><Skeleton height={360} /></div>
                        })
                    }
                </div>
                    </Container>
                </div>

                <div className={classes.whiteBackgroundContainer}>
                    <div className={classes.gradientDeepBlueBackgroundSkewed}>
                        <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                            <div className={classes.skew6degPaddingTopBottom15em}>
                                <div className={classes.displayGridTwoColumns}>
                                    <div>
                                        <div>
                                            <span className={classes.fontSize1x35emColorBlueBold}>
                                                We're here to make life easier
                                            </span>
                                        </div>
                                        <div className={classes.paddingTop2x7em}>
                                            <span className={classes.fontSize38x1pxBoldWhite}>
                                                The State and Federal Department of Labor requires that every employer posts a series of ever-changing Labor Law Posters.
                                            </span>
                                        </div>
                                        <div className={classes.paddingTop2x7em}>
                                            <span className={classes.fontSize18x1pxColorGray}>
                                                These posters must be in plain sight for all employees and they are often changed multiple times per year which causes an alarmingly high percentage of businesses to be out of compliance and at risk of a <strong style={{ color: '#19a1ff' }}>heavy fine</strong>.
                                            </span>
                                        </div>
                                        <div className={classes.paddingTop2x7em}>
                                            <NavLink to="/products" className={classes.linkClass}>
                                                <Button variant="contained"
                                                    color="primary" disableElevation
                                                    classes={{ root: classes.headerStartNowButton }}>
                                                    BECOME COMPLIANT
                                                </Button>
                                            </NavLink>
                                        </div>
                                        <div className={classes.paddingTop2x7emDisplayGridTwoColumnsGap3em}>
                                            <div>
                                                <div>
                                                    <CloudIcon className={classes.mainColorFontSize42px} />
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <span className={classes.colorWhiteFontSize15x6pxBoldBorderLeftBluePaddingLeft0x9em}>
                                                        Solution for all businesses
                                                    </span>
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <span className={classes.fontSize14x4pxColorGray}>
                                                        We provide solutions for start-ups to corporate and everything in between
                                                    </span>
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <NavLink to="/products" className={classes.linkClass}>
                                                        <Button
                                                            color="primary" disableElevation
                                                            classes={{ root: classes.defaultButtonNoPadding }}>
                                                            Learn More
                                                        </Button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <LayersIcon className={classes.mainColorFontSize42px} />
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <span className={classes.colorWhiteFontSize15x6pxBoldBorderLeftBluePaddingLeft0x9em}>
                                                        Automatic updates
                                                    </span>
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <span className={classes.fontSize14x4pxColorGray}>
                                                        Sent right to your office whenever a required change occurs
                                                    </span>
                                                </div>
                                                <div className={classes.paddingTop0x75em}>
                                                    <NavLink to="/products" className={classes.linkClass}>
                                                        <Button
                                                            color="primary" disableElevation
                                                            classes={{ root: classes.defaultButtonNoPadding }}>
                                                            Learn More
                                                        </Button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.secondLayoutGridImage}>
                                        <img src={Dashboard} width="100%" alt="Filingcomplianceservicesdata Filingcomplianceservices image of dashboard" />
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>

                <div className={classes.backgroundWhiteMarginTopMinus15em}>
                    <div className={classes.paddingTop30emBottom12em}>
                        <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                            <span className={classes.fontSize18x1pxColorGreenBold}>
                                Why Us
                            </span>
                            <div className={classes.paddingTop2x7em}>
                                <span className={classes.fontSize38x1px}>
                                    We are here to make the worry of labor law posting fines a thing of the past
                                </span>
                            </div>
                            <div className={classes.paddingTop2x7emDisplayGridFourColumnsGap3em}>
                                <div>
                                    <div>
                                        <PeopleIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Green Posters
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorGray}>
                                            Choose the budget and environmentally friendly Green Posters
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <SettingsIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Laminated Posters
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorGray}>
                                            The ultra durable and beautfiul laminated posters for high end officies
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <SelectAllIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Poster Subscription
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorGray}>
                                            Automatic updates sent right to your office whenever a required change occurs
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <SlowMotionVideoIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Team of professionals
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorGray}>
                                            Years of experience updating our posters daily and staying up to date with the law
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>

                <div style={{ backgroundColor: '#f4f6f8' }}>
                    <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                        <div className={classes.paddingTopBottom9em}>
                            <div className={classes.displayGrid1MainColumn2SideColumnsGap3em}>
                                <div>
                                    <span className={classes.fontSize24pxBold}>
                                        Ready to become compliant?
                                    </span>
                                    <div className={classes.paddingTop2x1em}>
                                        <span className={classes.fontSize16x5emColorLightDark}>
                                            Stay focused on your product, leave the
                                        </span>
                                        <span className={classes.fontSize16x5emColorLightDark}>
                                            law to us and become compliant today.
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop2x1em}>
                                        <NavLink to="/products" className={classes.linkClass}>
                                            <Button variant="contained"
                                                color="primary" disableElevation
                                                size="small"
                                                classes={{ root: classes.headerStartNowButton }}>
                                                Get Started
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={classes.paddingTop2x1emDown840}>
                                    <div>
                                        <MemoryIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Worry Free
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorLightDark}>
                                            We are here to make the worry of labor law posting fines a thing of the past
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <NavLink to="/product" className={classes.linkClass}>
                                            <Button
                                                color="primary" disableElevation
                                                classes={{ root: classes.defaultButtonNoPadding }}>
                                                Start Now
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={classes.paddingTop2x1emDown840}>
                                    <div>
                                        <TimelineIcon className={classes.mainColorFontSize42px} />
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize15x6emBoldBorderLeftBlue}>
                                            Work with the best
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <span className={classes.fontSize14x4pxColorLightDark}>
                                            Team of professionals with years of experience updating our posters daily
                                        </span>
                                    </div>
                                    <div className={classes.paddingTop0x75em}>
                                        <NavLink to="/products" className={classes.linkClass}>
                                            <Button
                                                color="primary" disableElevation
                                                classes={{ root: classes.defaultButtonNoPadding }}>
                                                Start Now
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    
                </div>

            </div>
            
        </div>
    )
}

export default Home;

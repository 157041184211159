import React from 'react';
import { NavLink } from 'react-router-dom';

import useStyles from '../styles/styles';

export default () => {
    const classes = useStyles();

    return (
        <div>
            <div style={{ padding: '0 30px' }}>
                <h1>Contact Us</h1>
                <p>For further inquiries, please feel free to contact us via email at <a href="mailto:support@laborcomplianceservice.com">support@laborcomplianceservice.com</a>.</p>
            </div>
        </div>
    )
}

import { observable, action } from 'mobx';

export class AlertStore {
    alertState: any = observable({
        message: '',
        state: '',
        show: false
    });

    dashboardAlerts: any = observable([]);

    setAlertState = action((name: any, value: any) => {
        this.alertState[name] = value;
    });

    pushDashboardAlert = action((value: object) => {
        this.dashboardAlerts.push(value);
    });

    clearDashboardAlerts = action(() => {
        this.dashboardAlerts.splice(0, this.dashboardAlerts.length);
    });
}

import React from 'react';

import Container from '@material-ui/core/Container';

import useStyles from '../styles/styles';

export default () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div style={{ padding: '30px' }}>

                <h1>Refund & Cancellation Policy</h1>
                <p>Last updated: November 23, 2021</p>
                <p>This Refund & Cancellation Policy describes Our policies and procedures on the refund & cancellation of our services and products.</p>
                <h1>Refund</h1>
                <p>If your item has not be recieved within 3 business weeks we will give you a full refund. Please contact us at support@laborcomplianceservice.com</p>
                <h1>Invoice Cancellation</h1>
                <p>If you made a payment by mistake or do not wish to receive the product, the cancellation can be made within 24h to 48h after payment.</p>
                <h1>Subscription Cancellation</h1>
                <p>You will be provided with a link to cancel subscription after payment or please contact us at support@laborcomplianceservice.com with your business info.</p>
                <p>In case you lost your link or need a new one please contact us at support@laborcomplianceservice.com</p>
                <p>Cancel subscription example link <a target="_blank" href="https://www.laborcomplianceservice.com/cancel/&customer=EXAMPLE&acc=">https://www.laborcomplianceservice.com/cancel/&customer=EXAMPLE&acc=</a></p>
                <h1>Contact Us</h1>
                <p>If you have any questions about this Refund & Cancellation Policy, You can contact us:</p>
                <ul>
                    <li>By email: support@laborcomplianceservice.com</li>
                </ul>

            </div>

        </React.Fragment>
    );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import DesktopMenu from '../components/DesktopMenu';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';

const DashboardLayout = (props) => {
    if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    } else {
        return (
            <div />
        )
    }
};

export default inject('alertStore')(observer((props) => {
    const navigate = useNavigate();

    const [loadingState, setLoadingState] = React.useState(true);
    const [ordersState, setOrdersState] = React.useState([]);

    React.useEffect(() => {
        if (!/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            navigate('/login');
        } else {
            fetch(`${process.env.API_URL}/notifications/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                }
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail !== undefined) {
                    for (const notification of res.detail) {
                        props.alertStore.pushDashboardAlert({
                            state: notification.state,
                            message: notification.notification
                        });
                    }

                    setTimeout(() => {
                        props.alertStore.clearDashboardAlerts();
                    }, 30000);
                }
            }).catch(err => {
                console.log(err);
            });
            
            fetch(`${process.env.API_URL}/orders/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                },
                body: JSON.stringify({ type: 'all', product: 'labor' })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail.data !== undefined) setOrdersState(res.detail.data);
                setLoadingState(false);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    const classes = useStyles();

    return (
        <DashboardLayout>

            <div className={classes.wrapper}>
                <div className={classes.homeGrid}>

                    <div className={classes.homeMenu}>

                        <DesktopMenu fontColor="black" />

                    </div>

                    <div className={classes.homeLayoutGrid} style={{ padding: '15px' }}>
                        {
                            loadingState ?
                            <LinearProgress />
                            :
                            ordersState.length === 0 ?
                            <div className={classes.roundDiv}>
                                No orders
                            </div>
                            :
                            ordersState.map(el => {
                                return (
                                    <div key={el.id} className={classes.roundDiv} style={{ display: 'flex', flexDirection: 'column', gap: '1.5em', marginTop: '0' }}>
                                        <div style={{ display: 'flex', gap: '0.9em', alignItems: 'center' }}>
                                            <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>ORDER #{el.id}</span>
                                            {
                                                el.product === 'reports' || el.product === 'labor' || el.product === 'certificate' || el.product === 'property' ?
                                                <a href={`/pay/&product=${el.product}&company=${el.company}&address=${el.address}&state=${el.state}&id=${el._id}&acc=${el.acc}&product_link=${el.json_product}`} style={{ textDecoration: 'none' }} target="_blank">
                                                    <Button variant="outlined" color="primary">Order Again</Button>
                                                </a>
                                                :
                                                <a href={`/products`} style={{ textDecoration: 'none' }} target="_blank">
                                                    <Button variant="outlined" color="primary">Order Again</Button>
                                                </a>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: '1.5em' }}>Paid</span>
                                            {
                                                el.paid ?
                                                <CheckIcon style={{ width: '1.5em', height: '1.17em', marginBottom: '0.15em' }} />
                                                :
                                                <CloseIcon style={{ width: '1.5em', height: '1.17em', marginBottom: '0.09em', marginLeft: '0.15em' }} />
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.15em' }}>
                                            <span style={{ fontSize: '1.5em' }}>Order made on</span>
                                            <span>{new Date(el.date).toLocaleString()}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.15em' }}>
                                            <span style={{ fontSize: '1.5em' }}>Contact information</span>
                                            {
                                                el.company !== '' &&
                                                <span>
                                                    {el.company}
                                                </span>
                                            }
                                            <span>
                                                {el.first_name}
                                            </span>
                                            <span>
                                                {el.last_name}
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.15em' }}>
                                            <span style={{ fontSize: '1.5em' }}>Shipping information</span>
                                            <span>{el.address}</span>
                                            <span>{el.city}</span>
                                            <span>{el.state}</span>
                                            <span>{el.phone}</span>
                                            <span style={{ fontSize: '0.9em', color: 'gray', marginTop: '15px' }}>Shipping delivery within 5 to 7 business days</span>
                                        </div>
                                        {
                                            el.downloadable.length > 0 && el.paid &&
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.15em' }}>
                                                <span style={{ fontSize: '1.5em' }}>Content</span>
                                                {
                                                    el.downloadable.map(item => {
                                                        return (
                                                            <a href={`https://api.${window.location.hostname}${item.path}`} target="_blank">
                                                                {item.name}
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                </div>
            </div>
        </DashboardLayout>
    );
}))

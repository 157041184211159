import * as React from 'react';

import useScript from '../hooks/useScript';

const HostedForm = ({
  authData,
  onSubmit,
  environment = 'SANDBOX',
  billingAddressOptions = { show: true, required: true },
  buttonText = 'Pay',
  formButtonText = 'Pay',
  formHeaderText = 'Pay',
  paymentOptions = { showCreditCard: true, showBankAccount: false },
  buttonStyle,
  buttonClassName,
  errorTextStyle,
  errorTextClassName,
  containerStyle,
  containerClassName,
  disabled,
  showErrorModal,
}) => {
  const [loading, setLoading] = React.useState(true);
  const scriptUrl =
    environment === 'PRODUCTION'
      ? 'https://js.authorize.net/v3/AcceptUI.js'
      : 'https://jstest.authorize.net/v3/AcceptUI.js';
  const [scriptLoaded, scriptError] = useScript(scriptUrl);

  React.useEffect(() => {
    if (scriptLoaded || scriptError) {
      setLoading(false);
    }
  }, [scriptLoaded, scriptError]);

  const responseHandler = React.useCallback(
    (response) => {
      if (response.messages.resultCode === 'Error') {
        typeof response.messages.message === 'string'
          ? showErrorModal(response.messages.message)
          : response.messages.message.map((error, index) => showErrorModal(error.text))
      } else {
        onSubmit(response);
      }
    },
    [onSubmit, showErrorModal]
  );


  React.useEffect(() => {
    if (!scriptError && !loading) {
      window.responseHandler = responseHandler;
    }
    if (scriptError)
      showErrorModal('There was a problem loading the Accept.JS script. Please try again.')
  }, [loading, scriptError, responseHandler, showErrorModal]);

  return (
    <div
      style={containerStyle}
      className={containerClassName ? containerClassName : undefined}
    >
      <button
        type="button"
        style={buttonStyle}
        className={buttonClassName ? `AcceptUI ${buttonClassName}` : 'AcceptUI'}
        data-billingaddressoptions={JSON.stringify(billingAddressOptions)}
        data-apiloginid={authData.apiLoginID}
        data-clientkey={authData.clientKey}
        data-acceptuiformbtntxt={formButtonText}
        data-acceptuiformheadertxt={formHeaderText}
        data-paymentoptions={JSON.stringify(paymentOptions)}
        data-responsehandler="responseHandler"
        disabled={scriptError || loading || disabled}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default HostedForm;
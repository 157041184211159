import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import DesktopMenu from '../components/DesktopMenu';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';

const DashboardLayout = (props) => {
    if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    } else {
        return (
            <div />
        )
    }
};

export default inject('alertStore')(observer((props) => {
    const navigate = useNavigate();

    const [loadingState, setLoadingState] = React.useState(true);
    const [accountState, setAccountState] = React.useState({
        email: ''
    });

    React.useEffect(() => {
        if (!/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            navigate('/login');
        } else {
            fetch(`${process.env.API_URL}/acc-info/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('lSj2aoXmn2')}`
                }
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(accRes => {
                setAccountState({
                    email: accRes.detail
                });
                setLoadingState(false);
            }).catch(err => {
                console.log(err);
            });
        }
    }, []);

    const classes = useStyles();

    return (
        <DashboardLayout>

            <div className={classes.wrapper}>
                <div className={classes.homeGrid}>

                    <div className={classes.homeMenu}>

                        <DesktopMenu fontColor="black" />

                    </div>

                    <div className={classes.homeLayoutGrid} style={{ padding: '15px' }}>
                        {
                            loadingState ?
                            <LinearProgress />
                            :
                            <div className={classes.roundDiv} style={{ display: 'flex', flexDirection: 'column', gap: '0.9em' }}>
                                <span style={{ fontSize: '1.65em' }}>Personal Information</span>
                                <span>Email {accountState.email}</span>
                                <a href={`mailto:support@${window.location.hostname}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button variant="outlined" color="primary" style={{ width: 'fit-content' }}>Contact Support</Button>
                                </a>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </DashboardLayout>
    );
}))

import { observable, action } from 'mobx';

export class ErrorStore {
    errorState: any = observable({
        usernameError: false,
        passwordError: false,
        emailError: false,
        usernameErrorText: '',
        passwordErrorText: '',
        emailErrorText: ''
    });

    setUsernameError = action((value: any) => {
        this.errorState.usernameError = value;
    });

    setPasswordError = action((value: any) => {
        this.errorState.passwordError = value;
    });

    setEmailError = action((value: any) => {
        this.errorState.emailError = value;
    });

    setUsernameErrorText = action((value: any) => {
        this.errorState.usernameErrorText = value;
    });

    setPasswordErrorText = action((value: any) => {
        this.errorState.passwordErrorText = value;
    });

    setEmailErrorText = action((value: any) => {
        this.errorState.emailErrorText = value;
    });
}

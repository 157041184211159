import React from 'react';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close'
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import DesktopMenu from './DesktopMenu';

import useStyles from '../styles/styles';
import { makeStyles } from '@material-ui/core/styles';

import { getCookie } from '../common/utils';

import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

export default inject('alertStore', 'formStore')(observer((props) => {
    const classes = useStyles();

    const [loadingState, setLoadingState] = React.useState(false);

    const submitApplication = () => {
        if (props.formStore.einState.llc_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill llc name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.num_of_members === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill amount of members');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.taxation_type === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill taxation type');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.first_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill first name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.last_name === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill last name');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.ssn !== props.formStore.einState.verify_ssn) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'SSN is incorrect');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.title === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill title');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.address === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.city === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.state === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.zipcode === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.county === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill county');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.mailing_address === '' && props.formStore.einState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing address');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.mailing_city === '' && props.formStore.einState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing city');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.mailing_state === '' && props.formStore.einState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.mailing_zipcode === '' && props.formStore.einState.diff_mailing_address) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill mailing zipcode');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.origin_country === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill origin country');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.origin_state === '' && props.formStore.einState.origin_country === 'United States') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill origin state');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.applying_reason === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill applying reason');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.primary_activity === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill primary activity');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.old_ein === '' && props.formStore.einState.previous_ein) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill previous federal EIN');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_first_wage_month === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill new employee first wage month');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_first_wage_day === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill new employee first wage day');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_first_wage_year === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill new employee first wage year');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_amount_agricultural === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill amount of new employees in agricultural');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_amount_household === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill amount of new employees in household');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.new_employee_amount_other === '' && props.formStore.einState.new_employee_within_year) {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill amount of new employees in other');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.business_acquired_month === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill business acquired month');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.business_acquired_day === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill business acquired day');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.business_acquired_year === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill business acquired year');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else if (props.formStore.einState.accounting_year_end === '') {
            window.scrollTo(0, 0);
            props.alertStore.setAlertState('show', true);
            props.alertStore.setAlertState('state', 'error');
            props.alertStore.setAlertState('message', 'Please fill accounting year end');

            setTimeout(() => {
                props.alertStore.setAlertState('show', false);
                props.alertStore.setAlertState('message', '');
            }, 4500);
            return;
        }

        else {
            props.formStore.setEinState('application_name', `${props.formStore.einState.llc_name.toUpperCase()}-${Math.floor(Date.now() / 1000)}`);
            setLoadingState(true);

            fetch(`${process.env.API_URL}/ein-submit/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: 'new', ...toJS(props.formStore.einState) })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail === 'Success') {
                    window.location.reload();
                } else {
                    window.scrollTo(0, 0);
                    props.alertStore.setAlertState('show', true);
                    props.alertStore.setAlertState('state', 'error');
                    props.alertStore.setAlertState('message', res.detail.invalid);
        
                    setTimeout(() => {
                        props.alertStore.setAlertState('show', false);
                        props.alertStore.setAlertState('message', '');
                    }, 4500);
                }
                setLoadingState(false);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    return (
        <div className={classes.roundDiv} style={{ marginTop: '1.8em' }}>
            <div className={classes.einInfoContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                    <span style={{ fontSize: '1.44em' }}>Limited Liability Company Information</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.llc_name} onChange={(e) => props.formStore.setEinState('llc_name', e.target.value)} variant="outlined" placeholder="LLC Name" label="LLC Name" fullWidth />
                        <TextField value={props.formStore.einState.dba} onChange={(e) => props.formStore.setEinState('dba', e.target.value)} variant="outlined" placeholder="DBA (Doing Business As) (Optional)" label="DBA (Doing Business As) (Optional)" fullWidth />
                    </div>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.num_of_members} onChange={(e) => props.formStore.setEinState('num_of_members', e.target.value)} variant="outlined" placeholder="Number of Members" label="Number of Members" fullWidth />
                        <TextField value={props.formStore.einState.taxation_type} onChange={(e) => props.formStore.setEinState('taxation_type', e.target.value)} variant="outlined" placeholder="Individual/Partnership" label="Taxation Type" fullWidth />
                    </div>
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


            <div className={classes.einInfoContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                    <span style={{ fontSize: '1.44em' }}>Principal Officer Information</span>
                    <span>Must match IRS records</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.first_name} onChange={(e) => props.formStore.setEinState('first_name', e.target.value)} variant="outlined" placeholder="First name" label="First name" fullWidth />
                        <TextField value={props.formStore.einState.middle_name} onChange={(e) => props.formStore.setEinState('middle_name', e.target.value)} variant="outlined" placeholder="Middle name" label="Middle name (Optional)" fullWidth />
                        <TextField value={props.formStore.einState.last_name} onChange={(e) => props.formStore.setEinState('last_name', e.target.value)} variant="outlined" placeholder="Last name" label="Last name" fullWidth />
                    </div>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.ssn} onChange={(e) => props.formStore.setEinState('ssn', e.target.value)} variant="outlined" placeholder="Social Security Number" label="Social Security Number" fullWidth />
                        <TextField value={props.formStore.einState.verify_ssn} onChange={(e) => props.formStore.setEinState('verify_ssn', e.target.value)} variant="outlined" placeholder="Verify Social Security Number" label="Verify Social Security Number" fullWidth />
                    </div>
                    <TextField value={props.formStore.einState.title} onChange={(e) => props.formStore.setEinState('title', e.target.value)} variant="outlined" placeholder="Owner / Developer / Manager" label="Title" fullWidth />
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


            <div className={classes.einInfoContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                    <span style={{ fontSize: '1.44em' }}>Business Address</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                    <TextField value={props.formStore.einState.address} onChange={(e) => props.formStore.setEinState('address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.city} onChange={(e) => props.formStore.setEinState('city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                        <TextField value={props.formStore.einState.state} onChange={(e) => props.formStore.setEinState('state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                        <TextField value={props.formStore.einState.zipcode} onChange={(e) => props.formStore.setEinState('zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                    </div>
                    <div style={{ display: 'flex', gap: '3em' }}>
                        <TextField value={props.formStore.einState.county} onChange={(e) => props.formStore.setEinState('county', e.target.value)} variant="outlined" placeholder="County" label="County" fullWidth />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.formStore.einState.diff_mailing_address}
                                    onChange={(e) => props.formStore.setEinState('diff_mailing_address', e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Different Mailing Address?"
                        />
                    </div>

                </div>
            </div>
            <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


            {
                props.formStore.einState.diff_mailing_address &&
                <>
                    <div className={classes.einInfoContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                            <span style={{ fontSize: '1.44em' }}>Business Mailing Address</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                            <TextField value={props.formStore.einState.mailing_address} onChange={(e) => props.formStore.setEinState('mailing_address', e.target.value)} variant="outlined" placeholder="Address" label="Address" fullWidth />
                            <div style={{ display: 'flex', gap: '0.45em' }}>
                                <TextField value={props.formStore.einState.mailing_city} onChange={(e) => props.formStore.setEinState('mailing_city', e.target.value)} variant="outlined" placeholder="City" label="City" fullWidth />
                                <TextField value={props.formStore.einState.mailing_state} onChange={(e) => props.formStore.setEinState('mailing_state', e.target.value)} variant="outlined" placeholder="State" label="State" fullWidth />
                                <TextField value={props.formStore.einState.mailing_zipcode} onChange={(e) => props.formStore.setEinState('mailing_zipcode', e.target.value)} variant="outlined" placeholder="Zipcode" label="Zipcode" fullWidth />
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />
                </>
            }



            <div className={classes.einInfoContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                    <span style={{ fontSize: '1.44em' }}>Basic information about your Business</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.origin_country} onChange={(e) => props.formStore.setEinState('origin_country', e.target.value)} variant="outlined" placeholder="Country of Origin" label="Country of Origin" fullWidth />
                        {
                            props.formStore.einState.origin_country === 'United States' &&
                            <TextField value={props.formStore.einState.origin_state} onChange={(e) => props.formStore.setEinState('origin_state', e.target.value)} variant="outlined" placeholder="State of Origin" label="State of Origin" fullWidth />
                        }
                    </div>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.applying_reason} onChange={(e) => props.formStore.setEinState('applying_reason', e.target.value)} variant="outlined" placeholder="Reason for Applying" label="Reason for Applying" fullWidth />
                        <TextField value={props.formStore.einState.primary_activity} onChange={(e) => props.formStore.setEinState('primary_activity', e.target.value)} variant="outlined" placeholder="Primary Activity" label="Primary Activity" fullWidth />
                    </div>
                    <TextField onChange={(e) => props.formStore.setEinState('specific_products', e.target.value)} variant="outlined" placeholder="Specific Products or Services" label="Specific Products or Services" fullWidth />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.highway_vehicle_55kpounds}
                                onChange={(e) => props.formStore.setEinState('highway_vehicle_55kpounds', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Does the business own a highway motor vehicle weighing 55,000 pounds or more?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.involve_gambling}
                                onChange={(e) => props.formStore.setEinState('involve_gambling', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Does the business involve gambling?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.manufacture_alcohol_tobacco_firearms}
                                onChange={(e) => props.formStore.setEinState('manufacture_alcohol_tobacco_firearms', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Does the business sell or manufacture alcohol, tobacco, or firearms?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.federal_excise_taxes}
                                onChange={(e) => props.formStore.setEinState('federal_excise_taxes', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Does your business pay federal excise taxes?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.previous_ein}
                                onChange={(e) => props.formStore.setEinState('previous_ein', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Has this entity applied for an EIN before?"
                    />
                    {
                        props.formStore.einState.previous_ein &&
                        <TextField value={props.formStore.einState.old_ein} onChange={(e) => props.formStore.setEinState('old_ein', e.target.value)} variant="outlined" placeholder="Previous Federal EIN" label="Previous Federal EIN" fullWidth />
                    }
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.less_than_4k_wages}
                                onChange={(e) => props.formStore.setEinState('less_than_4k_wages', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="If you expect to pay less than $4,000 in wages over the next calendar year, do you wish to file annual instead of quarterly taxes?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.formStore.einState.new_employee_within_year}
                                onChange={(e) => props.formStore.setEinState('new_employee_within_year', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Do you already have or expect to hire an employee within 12 months, excluding owners?"
                    />
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />


            {
                props.formStore.einState.new_employee_within_year &&
                <>
                    <div className={classes.einInfoContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                            <span style={{ fontSize: '1.44em' }}>Employee Information</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                            <span>Date of first wage payment</span>
                            <div style={{ display: 'flex', gap: '0.45em' }}>
                                <TextField value={props.formStore.einState.new_employee_first_wage_month} onChange={(e) => props.formStore.setEinState('new_employee_first_wage_month', e.target.value)} variant="outlined" placeholder="January" label="Month" fullWidth />
                                <TextField value={props.formStore.einState.new_employee_first_wage_day} onChange={(e) => props.formStore.setEinState('new_employee_first_wage_day', e.target.value)} variant="outlined" placeholder="1" label="Day" fullWidth />
                                <TextField value={props.formStore.einState.new_employee_first_wage_year} onChange={(e) => props.formStore.setEinState('new_employee_first_wage_year', e.target.value)} variant="outlined" placeholder="2022" label="Year" fullWidth />
                            </div>
                            <span>Number of Employees</span>
                            <div style={{ display: 'flex', gap: '0.45em' }}>
                                <TextField value={props.formStore.einState.new_employee_amount_agricultural} onChange={(e) => props.formStore.setEinState('new_employee_amount_agricultural', e.target.value)} variant="outlined" placeholder="0" label="Agricultural" fullWidth />
                                <TextField value={props.formStore.einState.new_employee_amount_household} onChange={(e) => props.formStore.setEinState('new_employee_amount_household', e.target.value)} variant="outlined" placeholder="0" label="Household" fullWidth />
                                <TextField value={props.formStore.einState.new_employee_amount_other} onChange={(e) => props.formStore.setEinState('new_employee_amount_other', e.target.value)} variant="outlined" placeholder="0" label="Other" fullWidth />
                            </div>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />
                </>
            }


            <div className={classes.einInfoContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.45em' }}>
                    <span style={{ fontSize: '1.44em' }}>Business Dates</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2em' }}>
                    <span>Date business acquired</span>
                    <div style={{ display: 'flex', gap: '0.45em' }}>
                        <TextField value={props.formStore.einState.business_acquired_month} onChange={(e) => props.formStore.setEinState('business_acquired_month', e.target.value)} variant="outlined" placeholder="January" label="Month" fullWidth />
                        <TextField value={props.formStore.einState.business_acquired_day} onChange={(e) => props.formStore.setEinState('business_acquired_day', e.target.value)} variant="outlined" placeholder="1" label="Day" fullWidth />
                        <TextField value={props.formStore.einState.business_acquired_year} onChange={(e) => props.formStore.setEinState('business_acquired_year', e.target.value)} variant="outlined" placeholder="2022" label="Year" fullWidth />
                    </div>
                    <span>Accounting Year End</span>
                    <TextField value={props.formStore.einState.accounting_year_end} onChange={(e) => props.formStore.setEinState('accounting_year_end', e.target.value)} variant="outlined" placeholder="2022" label="Year" fullWidth />
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #e6e6e6', margin: '3em 0' }} />

            <Button
                variant="contained"
                fullWidth
                style={{ color: '#fff', height: '51px' }}
                color="primary"
                onClick={submitApplication}
                disabled={loadingState}>
                Submit Application

                {loadingState && <CircularProgress size={13} className={classes.buttonProgress} />}
            </Button>
            <span style={{ display: 'flex', justifyContent: 'center' }}>By clicking on "Submit Application" you are agreeing to our Terms of Service and Privacy Policy.</span>

        </div>
    );
}))

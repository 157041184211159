import React from 'react';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/Mail';

import useStyles from '../styles/styles';

export default function Footer() {
    const classes = useStyles();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.3em',
            backgroundColor: '#fff',
            border: '1px solid #e8e9e9',
            padding: '30px',
            position: 'absolute',
            left: '0',
            right: '0'
        }}>
            <NavLink to="/terms" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Terms and Conditions</NavLink>
            <div style={{ padding: '0 4.5px', display: 'inline' }} />
            <NavLink to="/privacy-policy" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Privacy & Cookies</NavLink>
            <div style={{ padding: '0 4.5px', display: 'inline' }} />
            <NavLink to="/refund-policy" exact activeClassName={classes.activeNavLink} className={classes.navLink}>Refund & Cancellation</NavLink>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Login from '../components/Login';

import { observer, inject } from 'mobx-react';
import { toJS } from "mobx";

import useStyles from '../styles/styles';

export default inject('errorStore', 'formStore', 'uiStateStore')(observer((props) => {
    const navigate = useNavigate();

    const [snackState, snackUseState] = useState({
        successText: '',
        errorText: '',
        success: false,
        error: false
    });

    useEffect(() => {
        if (/^(.*;)?\s*bG9naW5jb29raWU\s*=/.test(document.cookie)) {
            navigate('/reports');
        }
    }, []);

    const classes = useStyles();

    const submitForm = (e) => {
        e.preventDefault();

        if (props.formStore.formState.email.length <= 3) {
            props.errorStore.setEmailError(true);
            props.errorStore.setEmailErrorText('Username is less than 3 characters');

        } else if (props.formStore.formState.password.length <= 3) {
            props.errorStore.setPasswordError(true);
            props.errorStore.setPasswordErrorText('Password is less than 3 characters');

        } else {
            props.errorStore.setEmailError(false);
            props.errorStore.setPasswordError(false);
            props.errorStore.setEmailErrorText('');
            props.errorStore.setPasswordErrorText('');
            props.formStore.setFormState(
                'email', props.formStore.formState.email.toLowerCase()
            );
            props.uiStateStore.setLoadingState(true);

            let formData = toJS(props.formStore.formState);

            fetch(`${process.env.API_URL}/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            }).then(res => res.json()).then(res => {
                if (res.access !== undefined) {
                    document.cookie = `lSj2aoXmn2=${res.access}; max-age=86400; path=/;`;
                    document.cookie = "bG9naW5jb29raWU=aXN0cnVl; max-age=86400; path=/;";

                    window.location.reload();
                } else {
                    snackUseState({
                        successText: '',
                        errorText: res.detail !== undefined ? res.detail : 'No found account. Please contact support',
                        success: true,
                        error: true
                    });
                }
                props.uiStateStore.setLoadingState(false);
            }).catch(err => {
                snackUseState({
                    ...snackState,
                    errorText: 'Error occured. Please try again',
                    error: true
                })
            });
        }
    };

    const setForm = (e) => props.formStore.setFormState(e.target.name, e.target.value);

    return (
        <div>
            <div className={classes.loginContainer}>
                <Container fixed maxWidth="xs">

                    <Snackbar open={snackState.success}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {snackState.successText}
                        </Alert>
                    </Snackbar>

                    <Snackbar open={snackState.error}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error">
                            {snackState.errorText}
                        </Alert>
                    </Snackbar>

                    <Login submitForm={submitForm}
                        setForm={setForm}
                        loadingState={props.uiStateStore.uiState.loadingState}
                        usernameError={props.errorStore.errorState.usernameError}
                        passwordError={props.errorStore.errorState.passwordError}
                        usernameErrorText={props.errorStore.errorState.usernameErrorText}
                        passwordErrorText={props.errorStore.errorState.passwordErrorText} />

                </Container>
            </div>
        </div>
    );
}));

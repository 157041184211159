import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import { observer, inject } from 'mobx-react';

import useStyles from '../styles/styles';

const Cart = inject('uiStateStore')(observer((props) => {
    const classes = useStyles();

    return (
        <div style={{ padding: '3em 0' }}>
            <Container maxWidth="lg" classes={{ maxWidthMd: classes.landingPageMainContainerSize }}>
                <div style={{ textAlign: 'center' }}>
                    <h1>Your Cart</h1>
                </div>
                {
                    props.uiStateStore.uiState.cart.length > 0 ?
                    <div className={classes.roundDiv} style={{ display: 'flow-root', padding: '0 3em 0 3em' }}>
                        {
                            props.uiStateStore.uiState.cart.map((el, ind) => {
                                return (
                                    <div key={ind} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #d9d9d9', padding: '2.1em 0 2.1em 0' }}>
                                        <div>
                                            <img src={el.img} width="60px" />
                                        </div>
                                        <NavLink to={`/product/${el.id}`} exact activeClassName={classes.linkNoDecorationFlex} className={classes.linkNoDecorationFlex}>
                                            <div className={classes.cartProductTitle}>
                                                <span>
                                                    {el.name}
                                                </span>
                                            </div>
                                        </NavLink>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>${el.price}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DeleteIcon onClick={() => props.uiStateStore.removeProduct(ind)} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div style={{ display: 'block', padding: '2.1em 0 2.1em 0', float: 'right' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5em' }}>
                                <span style={{ fontSize: '1.5em' }}>Total: ${props.uiStateStore.totalCheckout()}</span>
                            </div>
                            <div className={classes.cartButtonsContainer}>
                                <NavLink to="/products" exact activeClassName={classes.title} className={classes.title}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        classes={{ root: classes.headerStartNowButton }}
                                        style={{ color: '#19a1ff' }}>
                                        Continue Shopping
                                    </Button>
                                </NavLink>
                                <NavLink to="/checkout" exact activeClassName={classes.title} className={classes.title}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        classes={{ root: classes.headerStartNowButton }}>
                                        Checkout
                                    </Button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={classes.roundDiv} style={{ display: 'flow-root', padding: '1.5em 3em 3em 3em' }}>
                        <h1>Cart Is Empty</h1>
                        <NavLink to="/products" exact activeClassName={classes.title} className={classes.title}>
                            <Button
                                variant="outlined"
                                color="primary"
                                classes={{ root: classes.headerStartNowButton }}
                                style={{ color: '#19a1ff' }}>
                                    ALL PRODUCTS
                            </Button>
                        </NavLink>
                    </div>
                }

            </Container>
        </div>
    )
}));

export default Cart;
